import React from "react";
import { Link } from "react-router-dom";

const SiteBreadcrumb = (props) => {
  const { pageCategory, pageName } = props;

  return (
    <div className="breadcrumb-bg">
      <div className="container-fluid">
        <div className={"breadcrumbs-text white-color"}>
          <ul>
            <li>
              <Link to="/" className="active">
                {"Home"}
              </Link>
            </li>
            {pageCategory && (
              <>
                <li>
                  <Link to={`/${pageCategory?.path}`} className="active">
                    {pageCategory ? pageCategory?.label : "Category"}
                  </Link>
                </li>
                <li>{pageName ? pageName : "Page Name"}</li>
              </>
            )}
            {!pageCategory && <li>{pageName ? pageName : "Page Name"}</li>}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SiteBreadcrumb;
