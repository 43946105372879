import React, { useEffect, useState } from "react";
import { AdministrationMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import AboutPart from "./AboutPart";
import { useParams } from "react-router-dom";
import ScrollToTop from "../../components/Common/ScrollTop";
import {
  VisionMission,
  Profile,
  Notifications,
} from "../../components/Profile";
import GalleryPart from "../../components/Gallary/gallary";
import RightSidebar from "../../components/Common/DirectoratesRightSidebar";

const Content = ({ setTitle }) => {
  const [data, setData] = useState([]);
  const { type } = useParams();
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    setShowNotifications(false);
    setNotifications([]);
    if (type === "chancellor") {
      setTitle("Chancellor");
      fetchData(JNTUK_API_Urls.Chancellor, setData);
    } else if (type === "vice-chancellor") {
      setTitle("Vice Chancellor");
      fetchData(JNTUK_API_Urls.Vice_Chancellor, setData);
    } else if (type === "rector") {
      setTitle("Rector");
      fetchData(JNTUK_API_Urls.Rector, setData);
    } else if (type === "registrar") {
      setTitle("Registrar");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.Registrar, setData);
    } else if (type === "osd") {
      setTitle("OSD");
      fetchData(JNTUK_API_Urls.OSD, setData);
    } else if (type === "sc-st-cell") {
      setTitle("SC & ST CELL");
      fetchData(JNTUK_API_Urls["SC_And_ST CELL"], setData);
    } else if (type === "academics-and-planning-dap") {
      setTitle("Director, Academic Planning");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DAP_Department, setData);
      fetchData(
        JNTUK_API_Urls.DAP_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "director-academic-audit") {
      setTitle("Director, Academic Audit");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DAA_Department, setData);
      fetchData(
        JNTUK_API_Urls.DAA_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "director-of-evaluation-de") {
      setTitle("Director of Evaluation (DE)");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DE_Department, setData);
      fetchData(
        JNTUK_API_Urls.DE_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "admissions-da") {
      setTitle("Director, Admissions");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DA_Department, setData);
      fetchData(
        JNTUK_API_Urls.DA_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (
      type === "director-industry-institute-interaction-placements-and-training"
    ) {
      setTitle("Director, IIIP & Training");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DIIIPT_Department, setData);
      fetchData(
        JNTUK_API_Urls.DIIIP_Training_Notifications +
          `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "director-research-and-development") {
      setTitle("Director, Research and Development");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DRD_Department, setData);
      fetchData(
        JNTUK_API_Urls.DRD_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "faculty-development-centre") {
      setTitle("Director, Faculty Development Centre");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_FDC_Department, setData);
      fetchData(
        JNTUK_API_Urls.FDC_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "infrastructure-development") {
      setTitle("Director, Infrastructure Development");
      // setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DID_Department, setData);
      // fetchData(
      //   JNTUK_API_Urls.DID_Notifications + `&pagination[page]=${currentPage}`,
      //   setNotifications
      // );
    } else if (type === "director-foreign-university-relations-dfur") {
      setTitle("Director, Foreign University Relations");
      // setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_FUR_Department, setData);
      // fetchData(
      //   JNTUK_API_Urls.FUR_Notifications + `&pagination[page]=${currentPage}`,
      //   setNotifications
      // );
    } else if (type === "institute-of-science-and-technology-ist") {
      setTitle("Director, Institute of Science and Technology");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DIST_Department, setData);
      fetchData(
        JNTUK_API_Urls.DIST_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "school-of-management-studies") {
      setTitle("Director, School of Management Studies");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_SOMS_Department, setData);
      fetchData(
        JNTUK_API_Urls.SOMS_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "internal-quality-assurance-cell") {
      setTitle("Director, IQAC");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DIQACLC_Department, setData);
      fetchData(
        JNTUK_API_Urls.DIQACLC_Notifications +
          `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "director-legal-and-govt-affairs") {
      setTitle("Director Legal & Government Affairs");
      fetchData(JNTUK_API_Urls.JNTUK_Government_Affairs_Department, setData);
    } else if (type === "director-it-infrastructure") {
      setTitle("Director, IT Infrastructure");
      fetchData(JNTUK_API_Urls.JNTUK_IT_Infrastructure_Department, setData);
    } else if (type === "research-innovation-centre") {
      setTitle("Director, Research Innovation Centre");
      fetchData(JNTUK_API_Urls.JNTUK_Research_Innovation_Department, setData);
    } else if (type === "director-empowerment-of-women-grievances") {
      setTitle("Director, Empowerment of Women & Grievances");
      setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DEWG_Department, setData);
      fetchData(
        JNTUK_API_Urls.DEWG_Notifications + `&pagination[page]=${currentPage}`,
        setNotifications
      );
    } else if (type === "director-bics") {
      setTitle("Director, BICS");
      // setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DBICS_Department, setData);
      // fetchData(
      //   JNTUK_API_Urls.DBICS_Notifications + `&pagination[page]=${currentPage}`,
      //   setNotifications
      // );
    } else if (type === "director-libraryn") {
      setTitle("Director, ODL & OL Programmes");
      // setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DODL_OLP_Department, setData);
      // fetchData(
      //   JNTUK_API_Urls.DODL_OLP_Notifications +
      //     `&pagination[page]=${currentPage}`,
      //   setNotifications
      // );
    } else if (type === "director-green-campus-initiatives") {
      setTitle("Director, Green Campus Initiatives");
      // setShowNotifications(true);
      fetchData(JNTUK_API_Urls.JNTUK_DGCI_Department, setData);
      // fetchData(
      //   JNTUK_API_Urls.DGCI_Notifications + `&pagination[page]=${currentPage}`,
      //   setNotifications
      // );
    }
    // else if (type === "director-university-games-and-sports") {
    //   setTitle("Director(I/C) Director, University Games & Sports");
    //   // setShowNotifications(true);
    //   fetchData(JNTUK_API_Urls.JNTUK_DUGAS_Department, setData);
    //   // fetchData(
    //   //   JNTUK_API_Urls.DGCI_Notifications + `&pagination[page]=${currentPage}`,
    //   //   setNotifications
    //   // );
    // } else if (type === "director-incubation") {
    //   setTitle("Director(I/C) Incubation");
    //   // setShowNotifications(true);
    //   fetchData(JNTUK_API_Urls.JNTUK_DI_Department, setData);
    //   // fetchData(
    //   //   JNTUK_API_Urls.DGCI_Notifications + `&pagination[page]=${currentPage}`,
    //   //   setNotifications
    //   // );
    // }
    else if (type === "principal-university-college-of-engineering-kakinada") {
      setTitle("Principal, University College of Engineering Kakinada");
      fetchData(JNTUK_API_Urls.JNTUK_UCEK_Principal, setData);
    } else if (
      type === "principal-university-college-of-engineering-narasaraopeta"
    ) {
      setTitle("Principal, University College of Engineering Narasaraopeta");
      fetchData(JNTUK_API_Urls.JNTUK_UCEN_Principal, setData);
    }
  }, [type, currentPage]);

  let tab1 = "About",
    tab2 = "Profile",
    tab3 = "Gallery",
    tab4 = "Vision & Mission",
    tab5 = "Notifications";
  const tabStyle = "intro-tabs tabs-box";

  return (
    <React.Fragment>
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 md-mb-30">
              <div className="about-intro">
                <AdministrationMenu />
                <SideBar />
              </div>
            </div>
            <div className="col-lg-9 pl-20 md-pl-14">
              <div className="content-wrapper intro-section">
                <h2 title={data?.data?.attributes?.Page_Heading}>
                  {data?.data?.attributes?.Page_Heading}
                </h2>
                <Tabs>
                  <TabList className={tabStyle}>
                    <Tab>
                      <button>{tab1}</button>
                    </Tab>
                    {/* <Tab>
                      <button>{tab2}</button>
                    </Tab>
                    <Tab>
                      <button>{tab3}</button>
                    </Tab>
                    <Tab>
                      <button>{tab4}</button>
                    </Tab> */}
                    {showNotifications && (
                      <Tab>
                        <button onClick={() => setCurrentPage(1)}>
                          {tab5}
                        </button>
                      </Tab>
                    )}
                  </TabList>

                  <TabPanel>
                    <AboutPart data={data} />
                  </TabPanel>

                  {/* <TabPanel>
                    <Profile data={data} />
                  </TabPanel> */}

                  {/* <TabPanel>
                    <div style={{ marginTop: "30px" }}>
                      <GalleryPart data={data} />
                    </div>
                  </TabPanel> */}

                  {/* <TabPanel>
                    <div className="row">
                      <div className="col-lg-8">
                        <VisionMission data={data} />
                      </div>
                      <div className="col-lg-4">
                        <RightSidebar data={data} />
                      </div>
                    </div>
                  </TabPanel> */}
                  {showNotifications && (
                    <TabPanel>
                      <Notifications
                        data={notifications}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </TabPanel>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default Content;
