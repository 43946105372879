import React from "react";
import { BlocksRenderer } from "../../components/BlockRenderer";
import { imageBaseURL } from "../../utils/fetchData";

const AboutPart = ({ data }) => {
  return (
    <div className="content white-bg pt-30">
      <div className="course-overview">
        <div className="inner-box">
          <div className="row rs-team style1 orange-color transparent-bg">
            <div className="col-lg-12 col-md-8 col-sm-12">
              <div className="mb-20">
                {data?.data && (
                  <img
                    src={
                      imageBaseURL +
                      data?.data?.attributes?.Image?.data?.attributes?.url ||
                      ""
                    }
                    title={data?.data?.attributes?.Title} alt={data?.data?.attributes?.Title} className=""
                  />
                )}
              </div>
              <h3 className="pName" title={data?.data?.attributes?.Title}>{data?.data?.attributes?.Title}</h3>
              <div style={{ color: 'black' }}>
                <div className="subTitle">
                  <BlocksRenderer
                    content={data?.data?.attributes?.Sub_Title || []}
                  />
                </div>
              </div>

            </div>
          </div>
          <BlocksRenderer content={data?.data?.attributes?.Body || []} />
        </div>
      </div>
    </div>
  );
};

export default AboutPart;
