import React, { useState, useEffect } from "react";
import { NotificationMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";
import { Link, useParams, useHistory } from "react-router-dom";
import { fetchData } from "../../utils/fetchData";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { BlocksRenderer } from "../../components/Notification Details Renderer";

const getChildPath = (type, setPath) => {
  let text = "Download Application Formats";
  if (!type) {
    setPath({ pageCategory: "", pageName: "Download Application Formats" });
    return null;
  }
  if (type === "examination") text = "Examination";
  else if (type === "academic") text = "Academic";
  else if (type === "anti-ragging") text = "Anti-Ragging";
  setPath({
    pageCategory: {
      label: "Download Application Formats",
      path: "download-app-formats",
    },
    pageName: text,
  });
  return null;
};
const SDDContent = () => {
  const [data, setData] = useState([]);
  const [path, setPath] = useState({
    pageCategory: "",
    pageName: "Download Application Formats",
  });
  const { type } = useParams();
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    getChildPath(type, setPath);
  }, [type]);
  useEffect(() => {
    fetchData(
      JNTUK_API_Urls.Download_Application_Formats +
        `&filters[Category][$contains]=${path?.pageName}`,
      setData
    );
  }, [path]);
  return (
    <React.Fragment>
      <SiteBreadcrumb
        pageCategory={path.pageCategory}
        pageName={path.pageName}
      />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 md-mb-30">
              <div className="about-intro">
                <NotificationMenu />
                <SideBar />
              </div>
            </div>
            <div className="col-lg-6 pl-20 md-pl-14">
              <div className="content-wrapper">
                <h3 className="heading" title="Under Graduate">
                  Download Application Formats
                </h3>
                <div className="content-section">
                  {!type ? (
                    <>
                      <Link to="/download-app-formats/examination">
                        Examination
                      </Link>
                      <br />
                      <Link to="/download-app-formats/academic">Academic</Link>
                      <br />
                      <Link to="/download-app-formats/anti-ragging">
                        Anti-Ragging
                      </Link>
                    </>
                  ) : (
                    <>
                      <div className="col-lg-12 pl-20 md-pl-14">
                        <div className="content-wrapper">
                          <div className="content-section">
                            <div className="notficationTitle">
                              {data?.data?.[0]?.attributes?.Title}
                            </div>
                            <div className="mb-5 mt-10">
                              <strong>Download(s)</strong>
                            </div>
                            <div className="downloadLinks">
                              <table className="table table-striped table-bordered">
                                <tbody>
                                  <BlocksRenderer
                                    content={
                                      data?.data?.[0]?.attributes?.Body || []
                                    }
                                  />
                                </tbody>
                              </table>
                            </div>
                            <div className="text-center mt-20">
                              <span onClick={goBack} style={{ cursor: "pointer" }}>
                                Go Back
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-3 pl-40 md-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default SDDContent;
