import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "../pages/home";
import Mission from "../pages/mission";
import About from "../pages/about";
import Gallery from "../pages/gallery";
import Contact from "../pages/College Registration";
import Error from "../pages/404";
import LoadTop from "../components/Common/ScrollTop/LoadTop";
import SWOC from "../pages/swoc";
import SDD from "../pages/sdd";
import InstitutionalBody from "../pages/institutional-bodies";
import Profile from "../pages/profile";
import ExecutiveCouncil from "../pages/executive council";
import AcademicSenate from "../pages/academic senate";
import BoardOfStudies from "../pages/board of studies";
import JNTUKContacts from "../pages/jntuk-contacts";
import GoverningHierarchy from "../pages/governing hierarchy";
import Methodology from "../pages/methodology";
import Admissions from "../pages/admissions";
import Research from "../pages/research";
import CircularAspects from "../pages/circular aspects";
import Teaching_Learning_Process from "../pages/teaching learning process";
import Student_Support_And_Progression from "../pages/student support and progression";
import Infrastructure_and_Learning_Resources from "../pages/infrastructure and learning resources";
import Convocations from "../pages/convocations";
import AutonomousColleges from "../pages/autonomous colleges";
import University_Examination_Fee_Structure from "../pages/University Examination Fee Structure";
import Affiliated_Colleges_East_Godavari from "../pages/affiliated colleges east godavari";
import Affiliated_Colleges_West_Godavari from "../pages/affiliated colleges west godavari";
import Affiliated_Colleges_Krishna from "../pages/affiliated colleges krishna";
import Affiliated_Colleges_Guntur from "../pages/affiliated colleges guntur";
import Affiliated_Colleges_Prakasam from "../pages/affiliated colleges prakasam";
import UniversitySong from "../pages/university song";
import Addl_Controller_of_Examinations from "../pages/addl controller of examinations";
import ExaminationUG from "../pages/ExaminationUG";
import ExaminationPgSDC from "../pages/ExaminationPgSDC";
import ProgrammesOffered from "../pages/programmes offered";
import UnderGraduate from "../pages/under graduate";
import PostGraduate from "../pages/post graduate";
import Courses from "../pages/courses";
import SpecialOfficers from "../pages/SpecialOfficers";
import SpecialOfficersProfile from "../pages/SpecialOfficersProfile";
import NAACReports from "../pages/naac-reports";
import Notifications from "../pages/notifications";
import Directorates from "../pages/Directorates";
import Convocation from "../pages/Convocation";
import Location from "../pages/location";
import Terms from "../pages/terms";
import WebPolicy from "../pages/web-policy";
import Disclaimer from "../pages/disclaimer";
import NotificationDetails from "../pages/notificationDetails";
import JNTUKViceChancellor from "../pages/former vice chancellors";
import GalleryALL from "../pages/galleryAll";
import CampusBuildings from "../pages/campus-buildings";
import DownloadAppFormats from "../pages/download-app-formats";

const App = () => {
  return (
    <div className="App">
      <Router>
        <LoadTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/jntukvision/:type?" component={Mission} />
          <Route path="/SWOC/:type?" component={SWOC} />
          <Route path="/phd-courses/:type?" component={Research} />
          <Route path="/SDD" component={SDD} />
          <Route path="/institutional-bodies" component={InstitutionalBody} />
          <Route path="/university-song" component={UniversitySong} />
          <Route path="/convocations/:id" component={Convocation} />
          <Route path="/convocations" component={Convocations} />
          <Route path="/profile/:type?" component={Profile} />
          <Route
            path="/controller-of-examination-ug"
            component={ExaminationUG}
          />
          <Route
            path="/controller-of-examinations-pg-sdc"
            component={ExaminationPgSDC}
          />
          <Route path="/executive-council" component={ExecutiveCouncil} />
          <Route path="/academic-senate" component={AcademicSenate} />
          <Route path="/directorates" component={Directorates} />
          <Route path="/board-of-studies" component={BoardOfStudies} />
          <Route path="/jntuk-contacts" component={JNTUKContacts} />
          <Route path="/governing-hierarchy" component={GoverningHierarchy} />
          <Route path="/methodology" component={Methodology} />
          <Route path="/admissions" component={Admissions} />
          <Route path="/academicsdata/:type?" component={CircularAspects} />
          <Route
            path="/teachingLearnProcess"
            component={Teaching_Learning_Process}
          />
          <Route
            path="/studentsupportprogression"
            component={Student_Support_And_Progression}
          />
          <Route
            path="/infrastructurelearning/:type?"
            component={Infrastructure_and_Learning_Resources}
          />

          <Route path="/autonomous-colleges" component={AutonomousColleges} />
          <Route
            path="/university-examination-fee-structure-of-various-courses"
            component={University_Examination_Fee_Structure}
          />
          <Route
            path="/addl-controller-of-examinations"
            component={Addl_Controller_of_Examinations}
          />
          <Route path="/programmes-offered" component={ProgrammesOffered} />
          <Route path="/under-graduate" component={UnderGraduate} />
          <Route path="/post-graduate" component={PostGraduate} />
          <Route path="/course/:id" component={Courses} />

          <Route path="/about" component={About} />
          <Route
            path="/special-officers-profile/:id"
            component={SpecialOfficersProfile}
          />
          <Route path="/special-officers" component={SpecialOfficers} />
          <Route
            path="/affiliated-colleges-east-godavari"
            component={Affiliated_Colleges_East_Godavari}
          />
          <Route
            path="/affiliated-colleges-west-godavari"
            component={Affiliated_Colleges_West_Godavari}
          />
          <Route
            path="/affiliated-colleges-krishna"
            component={Affiliated_Colleges_Krishna}
          />
          <Route
            path="/affiliated-colleges-guntur"
            component={Affiliated_Colleges_Guntur}
          />
          <Route
            path="/affiliated-colleges-prakasam"
            component={Affiliated_Colleges_Prakasam}
          />
          <Route path="/naac-reports" component={NAACReports} />
          <Route path="/location-map" component={Location} />
          <Route path="/terms-conditions" component={Terms} />
          <Route path="/website-policy" component={WebPolicy} />
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/category" component={Notifications} />
          <Route path="/notification" component={NotificationDetails} />
          <Route
            path="/jntuk-vice-chancellor"
            component={JNTUKViceChancellor}
          />
          <Route path="/campus-buildings" component={CampusBuildings} />
          <Route path="/gallery/:type?" component={Gallery} />
          <Route path="/gallery-all" component={GalleryALL} />
          <Route path="/college-registration-a9bZc8dYf7Xg6Hh5Ii4Jj3Kk2Ll1Mm0Nn9Oo8Pp7Qq6Rr5Ss4Tt3Uu2V" component={Contact} />
          <Route path="/download-app-formats/:type?" component={DownloadAppFormats} />
          <Route component={Error} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
