import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SearchModal from "../../components/Layout/Header/SearchModal";
// Image
import footerLogo from "../../assets/img/logo/lite-logo.png";
import Accreditation from "../../components/Common/Accreditation";
import ContactMain from "./ContactMain";
const About = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>College Registration | JNTU Kakinada</title>
        <meta name="description" content="Contact Jawaharlal Nehru Technological University Kakinada" />
        <meta name="keywords" content="Contact Jawaharlal Nehru Technological University Kakinada"></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="College Registration"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />

      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle="College Registration"
          pageName="College Registration"
          breadcrumbsClass="breadcrumb-bg"
        />
        {/* breadcrumb-area-start */}

        <div id="rs-about" className="rs-about style3 pt-50 pb-50">
          <div className="container">
            <div className="row">
              {/* <div className="col-lg-3 md-mb-30">
                <div className="about-intro">
                  <AboutMenu />
                  <SideBar />
                </div>
              </div> */}
              <div className="col-lg-12 pl-82 md-pl-14">
                <div className="row">
                  <div className="rs-inner-blog col-lg-12">
                    {/* ContactMain Section Start */}
                    <ContactMain />
                    {/* ContactMain Section End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accreditation />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default About;
