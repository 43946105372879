import React, { useState } from "react";
import { Link } from "react-router-dom";
import { imageBaseURL } from "../../utils/fetchData";
import { BlocksRenderer } from "../../components/BlockRenderer";
import ScrollToTop from "../../components/Common/ScrollTop";
import { formatDate } from "../../utils/methods";
import Pagination from "../Pagination";

export const ProfileGallery = ({ data }) => {
  return (
    <div style={{ display: "flex", marginTop: "30px" }}>
      {data?.data?.attributes?.Gallery?.data?.map((obj) => (
        <img
          style={{ margin: "10px" }}
          src={imageBaseURL + obj?.attributes?.formats?.thumbnail?.url || ""}
          alt="gallery img"
          title="gallery img"
        />
      ))}
    </div>
  );
};

export const VisionMission = ({ data }) => {
  return (
    <div style={{ marginTop: "30px" }}>
      <BlocksRenderer
        content={data?.data?.attributes?.Vision_And_Mission || []}
      />
    </div>
  );
};

export const Profile = ({ data }) => {
  return (
    <div className="content white-bg pt-30">
      <div className="course-overview">
        <div className="inner-box">
          <h5 title="Education">Education</h5>
          {data?.data?.attributes?.Education && (
            <BlocksRenderer content={data?.data?.attributes?.Education || []} />
          )}

          {data?.data?.attributes?.jntuk_directorate?.data?.attributes
            ?.Education && (
            <BlocksRenderer
              content={
                data?.data?.attributes?.jntuk_directorate?.data?.attributes
                  ?.Education || []
              }
            />
          )}
          {data?.data?.attributes?.sc_and_st_cell_user?.data?.attributes
            ?.Education && (
            <BlocksRenderer
              content={
                data?.data?.attributes?.sc_and_st_cell_user?.data?.attributes
                  ?.Education || []
              }
            />
          )}

          <h5 title="Research Interests">Research Interests</h5>
          {data?.data?.attributes?.Research_Interests && (
            <BlocksRenderer
              content={data?.data?.attributes?.Research_Interests || []}
            />
          )}

          {data?.data?.attributes?.jntuk_directorate?.data?.attributes
            ?.Research_Interests && (
            <BlocksRenderer
              content={
                data?.data?.attributes?.jntuk_directorate?.data?.attributes
                  ?.Research_Interests || []
              }
            />
          )}
          {data?.data?.attributes?.sc_and_st_cell_user?.data?.attributes
            ?.Research_Interests && (
            <BlocksRenderer
              content={
                data?.data?.attributes?.sc_and_st_cell_user?.data?.attributes
                  ?.Research_Interests || []
              }
            />
          )}
          <h5 title="Teaching Interests">Teaching Interests</h5>
          {data?.data?.attributes?.Teaching_Interests && (
            <BlocksRenderer
              content={data?.data?.attributes?.Teaching_Interests || []}
            />
          )}

          {data?.data?.attributes?.jntuk_directorate?.data?.attributes
            ?.Teaching_Interests && (
            <BlocksRenderer
              content={
                data?.data?.attributes?.jntuk_directorate?.data?.attributes
                  ?.Teaching_Interests || []
              }
            />
          )}
          {data?.data?.attributes?.sc_and_st_cell_user?.data?.attributes
            ?.Teaching_Interests && (
            <BlocksRenderer
              content={
                data?.data?.attributes?.sc_and_st_cell_user?.data?.attributes
                  ?.Teaching_Interests || []
              }
            />
          )}
          <h5 title="Awards and Honors">Awards and Honors</h5>
          {data?.data?.attributes?.Awards_and_Honors && (
            <BlocksRenderer
              content={data?.data?.attributes?.Awards_and_Honors || []}
            />
          )}

          {data?.data?.attributes?.jntuk_directorate?.data?.attributes
            ?.Awards_And_Honors && (
            <BlocksRenderer
              content={
                data?.data?.attributes?.jntuk_directorate?.data?.attributes
                  ?.Awards_And_Honors || []
              }
            />
          )}
          {data?.data?.attributes?.sc_and_st_cell_user?.data?.attributes
            ?.Awards_And_Honors && (
            <BlocksRenderer
              content={
                data?.data?.attributes?.sc_and_st_cell_user?.data?.attributes
                  ?.Awards_And_Honors || []
              }
            />
          )}
          <h5 title="Publications">Publications</h5>
          {data?.data?.attributes?.Publications && (
            <BlocksRenderer
              content={data?.data?.attributes?.Publications || []}
            />
          )}

          {data?.data?.attributes?.jntuk_directorate?.data?.attributes
            ?.Publications && (
            <BlocksRenderer
              content={
                data?.data?.attributes?.jntuk_directorate?.data?.attributes
                  ?.Publications || []
              }
            />
          )}
          {data?.data?.attributes?.sc_and_st_cell_user?.data?.attributes
            ?.Publications && (
            <BlocksRenderer
              content={
                data?.data?.attributes?.sc_and_st_cell_user?.data?.attributes
                  ?.Publications || []
              }
            />
          )}
        </div>

        <div className="downloadBtns">
          {data?.data?.attributes?.Download_Bio_Data?.data?.attributes?.url && (
            <a
              href={`${imageBaseURL}${data?.data?.attributes?.Download_Bio_Data?.data?.attributes?.url}`}
              target="_blank"
            >
              Download Biodata <i className="fad fa-file-pdf"></i>
            </a>
          )}
          {data?.data?.attributes?.Download_Profile_Data?.data?.attributes?.url && (
            <a
              href={`${imageBaseURL}${data?.data?.attributes?.Download_Profile_Data?.data?.attributes?.url}`}
              target="_blank"
            >
              Download Profiledata <i className="fad fa-file-pdf"></i>
            </a>
          )}
          {data?.data?.attributes?.jntuk_directorate?.data?.attributes
            ?.Download_Bio_Data?.data?.attributes?.url && (
            <a
              href={`${imageBaseURL}${data?.data?.attributes?.jntuk_directorate?.data?.attributes?.Download_Profile_Data?.data?.attributes?.url}`}
              target="_blank"
            >
              Download Biodata <i className="fad fa-file-pdf"></i>
            </a>
          )}
          {data?.data?.attributes?.jntuk_directorate?.data?.attributes
            ?.Download_Profile_Data?.data?.attributes?.url && (
            <a
              href={`${imageBaseURL}${data?.data?.attributes?.jntuk_directorate?.data?.attributes?.Download_Profile_Data?.data?.attributes?.url}`}
              target="_blank"
            >
              Download Profiledata <i className="fad fa-file-pdf"></i>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
function convertUrl(inputString) {
  let stringWithUnderscores = inputString.replace(/-/g, " ");
  stringWithUnderscores = stringWithUnderscores.replace(/_/g, "&");

  return stringWithUnderscores;
}

function convertToPath(inputString) {
  // Replace spaces with dashes and remove special characters
  const path = inputString
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-|-$/g, "");

  // Encode the resulting string
  const encodedPath = encodeURIComponent(path);

  return encodedPath;
}
export const Notifications = ({ data, currentPage, setCurrentPage }) => {
  const [itemsPerPage] = useState(25);
  const totalItems = data?.meta?.pagination?.total;
  const [type, setType] = useState("Notifications and Circulars");
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div id="rs-about" className="rs-about style3 pt-30 pb-50 md-pt-30">
        <div className="">
          <div className="">
            <div className="content-wrapper">
              <div className="content-section">
                <div className="overflow-x">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">S.No</th>
                        <th scope="col" style={{ width: "100px" }}>
                          Date
                        </th>
                        <th scope="col">Notification Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.map((obj, index) => (
                        <tr key={(currentPage - 1) * itemsPerPage + index + 1}>
                          <td style={{ color: "#ff5421", fontWeight: "700" }}>
                            {((currentPage - 1) * itemsPerPage + index + 1)
                              .toString()
                              .padStart(2, "0")}
                            .
                          </td>
                          <td style={{ width: "100px", fontWeight: "700" }}>
                            {formatDate(obj?.attributes?.Start_Date) ||
                              formatDate(obj?.attributes?.Publish_Date)}
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: `/notification/${convertToPath(
                                  obj?.attributes?.Title
                                )}`,
                                state: {
                                  notification: obj,
                                  category: convertUrl(type),
                                },
                              }}
                            >
                              {obj?.attributes?.notification_department?.data
                                ?.attributes?.Prefix
                                ? obj?.attributes?.notification_department?.data
                                    ?.attributes?.Prefix + " - "
                                : ""}
                              {obj?.attributes?.Title}
                            </Link>
                          </td>
                        </tr>
                      ))}
                      {data?.data?.length === 0 && (
                        <tr>
                          <td align="center" colSpan={3}>
                            Notifications are not available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  currentPage={currentPage}
                  paginate={paginate}
                  arrayLength={totalItems / itemsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default Notifications;
