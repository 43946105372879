import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuItems from "./MenuItems";
import RSMobileMenu from "./RSMobileMenu";
import TopHeader from "./TopBar";

import normalLogo from "../../../assets/img/logo/Jntuk-white-logo.png";
import darkLogo from "../../../assets/img/logo/Jntuk-logo.png";

import { imageBaseURL } from "../../../utils/fetchData";
import { JNTUK_PDF_Links } from "../../../utils/api.config";

const Header = (props) => {
  const {
    headerClass,
    parentMenu,
    secondParentMenu,
    activeMenu,
    headerNormalLogo,
    headerStickyLogo,
    mobileNormalLogo,
    mobileStickyLogo,
    TopBar,
    TopBarClass,
    emailAddress,
    phoneNumber,
    Location,
    CanvasLogo,
    CanvasClass,
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Sticky is displayed after scrolling for 100 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const searchModalAdd = () => {
    document.body.classList.add("modal-open", "overflow-hidden");
  };

  const canvasMenuAdd = () => {
    document.body.classList.add("nav-expanded");
  };

  const externalLink = (url) => {
    const userConfirmed = window.confirm(
      "You are being redirected to an external website. Please note that JNTUK Portal is not responsible for external websites content & privacy policies."
    );

    if (userConfirmed) {
      window.open(url, "_blank");
    } else {
      return null;
    }
  };
  const subDomain = (url) => {
    const userConfirmed = window.confirm(
      "You are being redirected to an internal sub-domain website. The sub-domain website will open in a New Tab"
    );

    if (userConfirmed) {
      window.open(url, "_blank");
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <div
        className={
          headerClass ? headerClass : "full-width-header home8-style4 main-home"
        }
      >
        <header id="rs-header" className="rs-header">
          {TopBar ? (
            <TopHeader
              topBarClass={TopBarClass}
              emailAddress={emailAddress}
              phoneNumber={phoneNumber}
              Location={Location}
            />
          ) : (
            ""
          )}

          <div
            className={
              isVisible ? "menu-area menu-sticky" : "menu-area menu-sticky"
            }
          >
            <div className="container-fluid">
              <div className="row y-middle">
                <div className="col-xl-3">
                  <div className="logo-area hidden-lg">
                    <Link to="/">
                      <img
                        className="normal-logo"
                        src={headerNormalLogo ? headerNormalLogo : normalLogo}
                        title="Jawaharlal Nehru Technological University Kakinada Logo"
                        alt="Jawaharlal Nehru Technological University Kakinada Logo"
                      />
                      <img
                        className="sticky-logo"
                        src={headerStickyLogo ? headerStickyLogo : darkLogo}
                        title="Jawaharlal Nehru Technological University Kakinada Logo"
                        alt="Jawaharlal Nehru Technological University Kakinada Logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-9 text-end">
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <div className="mobile-menu lg-display-block">
                        <Link to="/" className="mobile-normal-logo">
                          <img
                            className="normal-logo"
                            src={
                              mobileNormalLogo ? mobileNormalLogo : normalLogo
                            }
                            title="Jawaharlal Nehru Technological University Kakinada Logo"
                            alt="Jawaharlal Nehru Technological University Kakinada Logo"
                          />
                        </Link>
                        <Link to="/" className="mobile-sticky-logo">
                          <img
                            src={mobileNormalLogo ? mobileNormalLogo : darkLogo}
                            title="Jawaharlal Nehru Technological University Kakinada Logo"
                            alt="Jawaharlal Nehru Technological University Kakinada Logo"
                          />
                        </Link>
                        <span
                          onClick={searchModalAdd}
                          className="rs-search bold-text mr-20"
                        >
                          <i className="far fa-search"></i>
                        </span>
                        <span
                          className="rs-menu-toggle"
                          onClick={() => {
                            setMenuOpen(!menuOpen);
                          }}
                        >
                          <i className="fa fa-bars"></i>
                        </span>
                      </div>
                      <nav className="rs-menu hidden-lg">
                        <ul className="nav-menu">
                          <MenuItems
                            parentMenu={parentMenu}
                            secondParentMenu={secondParentMenu}
                            activeMenu={activeMenu}
                          />
                          <li className="rs-mega-menu menu-item-has-children additional-menus submenuRightAligned">
                            <Link to="#">Information For...</Link>
                            <ul className="mega-menu">
                              <li className="mega-menu-container">
                                <div className="single-megamenu">
                                  <ul className="sub-menu">
                                    <li>
                                      <Link to="/campus-buildings">
                                        Campus Building
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/convocations">
                                        Convocations
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/category/Latest-Events">
                                        Latest Events
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/jntuk-contacts">Contacts</Link>
                                    </li>
                                    <li>
                                      <a
                                        href={
                                          "https://infyspringboard.onwingspan.com/web/en/page/lex_auth_0136054520526520321"
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          externalLink(
                                            "https://infyspringboard.onwingspan.com/web/en/page/lex_auth_0136054520526520321"
                                          );
                                        }}
                                        onContextMenu={(e) => {
                                          return null;
                                        }}
                                      >
                                        Infosys Springboard
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href={"http://pc.jntuk.edu.in"}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          externalLink(
                                            "http://pc.jntuk.edu.in"
                                          );
                                        }}
                                        onContextMenu={(e) => {
                                          return null;
                                        }}
                                      >
                                        Placement Cell
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href={`${imageBaseURL}${JNTUK_PDF_Links.JNTUK_Newsletter}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        JNTUK Newsletter
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="single-megamenu">
                                  <ul className="sub-menu">
                                    <li>
                                      <a
                                        href={"https://bcde.online"}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          externalLink("https://bcde.online");
                                        }}
                                        onContextMenu={(e) => {
                                          return null;
                                        }}
                                      >
                                        Board for Community Development through
                                        Education (BCDE)
                                      </a>
                                    </li>
                                  </ul>
                                  <ul className="sub-menu">
                                    <li>
                                      <Link to="#">JNTUK UNITS</Link>
                                      <ul>
                                        <li>
                                          <Link to="/profile/sc-st-cell">
                                            SC & ST Cell
                                          </Link>
                                        </li>
                                        <li>
                                          <a
                                            href={"http://arc.jntuk.edu.in"}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              subDomain(
                                                "http://arc.jntuk.edu.in"
                                              );
                                            }}
                                            onContextMenu={(e) => {
                                              return null;
                                            }}
                                          >
                                            Anti Ragging Cell
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={`${imageBaseURL}${JNTUK_PDF_Links.Sexual_Harassment_Committees}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Sexual Harassment Committees
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={"https://jntuknss.org"}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              externalLink(
                                                "https://jntuknss.org"
                                              );
                                            }}
                                            onContextMenu={(e) => {
                                              return null;
                                            }}
                                          >
                                            NSS Cell
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={"https://jntukiqac.com"}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              externalLink(
                                                "https://jntukiqac.com"
                                              );
                                            }}
                                            onContextMenu={(e) => {
                                              return null;
                                            }}
                                          >
                                            IQAC
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={"https://ecosak.org.in"}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              externalLink(
                                                "https://ecosak.org.in"
                                              );
                                            }}
                                            onContextMenu={(e) => {
                                              return null;
                                            }}
                                          >
                                            Alumni Engagement
                                          </a>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                                <div className="single-megamenu">
                                  <ul className="sub-menu">
                                    <li>
                                      <Link to="#">
                                        IIIP & SKILL DEVELOPMENT
                                      </Link>
                                      <ul>
                                        <li>
                                          <a
                                            href={`${imageBaseURL}${JNTUK_PDF_Links.Placements_Graph}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Placements Graph
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={`${imageBaseURL}${JNTUK_PDF_Links.Visits_to_Industry}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Visits to Industry's
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={`${imageBaseURL}${JNTUK_PDF_Links.Lab_Openings}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Lab Openings
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={`${imageBaseURL}${JNTUK_PDF_Links.Skill_Development_Programmes}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Skill Development Programmes
                                          </a>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </li>
                          <li className="sidebarmenu-search">
                            <Link
                              to="#"
                              onClick={searchModalAdd}
                              className="rs-search"
                              href="#"
                              aria-label="Search"
                            >
                              <i className="flaticon-search"></i>
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RSMobileMenu
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            parentMenu={parentMenu}
            secondParentMenu={secondParentMenu}
          />
          <div
            onClick={() => setMenuOpen(false)}
            className={menuOpen ? "body-overlay show" : "body-overlay"}
          ></div>
        </header>
      </div>
    </React.Fragment>
  );
};

export default Header;
