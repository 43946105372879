import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SectionTitle from "../../components/Common/SectionTitle";

// About Image
import { fetchData, imageBaseURL } from "../../utils/fetchData";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { BlocksRenderer } from "../../components/BlockRenderer";

const AboutText = (props) => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  useEffect(() => {
    fetchData(JNTUK_API_Urls["About_JNTUK"], setData);
  }, []);
  useEffect(() => {
    setData2(data?.data?.attributes?.Body?.splice(0, 4));
  }, [data]);
  useEffect(() => {
    fetchData(JNTUK_API_Urls["Vice_Chancellor"], setData1);
  }, []);

  return (
    <div className="rs-about style1 pt-30 pb-30 md-pt-30 md-pb-30">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 padding-0 pr-40 md-pl-14 md-pr-14 md-mb-30">
            <div className="img-part">
              <img
                className="about-main"
                src={
                  imageBaseURL +
                    data1?.data?.attributes?.Image?.data?.attributes?.url || ""
                }
                alt={data1?.data?.attributes?.Title}
                title={data1?.data?.attributes?.Title}
              />
              <div className="info-part mt-14">
                <h2 className="white" title={data1?.data?.attributes?.Title}>{data1?.data?.attributes?.Title}</h2>
                <div className="exInfo white">
                  <BlocksRenderer
                    content={data1?.data?.attributes?.Sub_Title || []}
                  />
                </div>
                {/* <div className="exInfo white">Hon'ble Vice-Chancellor</div>
                <div className="exInfo white">JNTUK, Kakinada</div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title"
                subtitleClass="sub-title orange text-capitalize"
                subtitle={data?.data?.attributes?.Title || ""}
                titleClass=""
                title=""
                descClass=""
                description=""
              />
              <BlocksRenderer content={data2 || []} />
              <div className="text-end mt-10">
                <Link
                  className="readon orange-btn main-home text-capitalize"
                  to="/about"
                >
                  See more
                </Link>
              </div>
              {/* Section Title End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutText;
