import React, { Component } from 'react';
import SliderDefault from '../../components/Slider/SliderDefault';
import Categories from './CategoriesSection';
import AboutText from './AboutTextSection';
import Events from './EventsSection';
import Happenings from './HappeningsSection';
import Accreditation from '../../components/Common/Accreditation';
import ScrollToTop from '../../components/Common/ScrollTop';

class HomeMain extends Component {

	render() {

		return (
			<React.Fragment>
				{/* SliderDefault-start */}
				<SliderDefault />
				{/* SliderDefault-start */}

				{/* AboutText Start */}
				<AboutText />
				{/* AboutText End */}

				<Events />
				<Happenings />

				{/* Categories-area-start */}
				<Categories />
				{/* Categories-area-end */}

				<Accreditation />

				{/* scrolltop-start */}
				<ScrollToTop
					scrollClassName="scrollup orange-color"
				/>
				{/* scrolltop-end */}

			</React.Fragment>
		);
	}
}

export default HomeMain;