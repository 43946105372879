import React, { useState, useEffect } from "react";
import { AcademicsMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { BlocksRenderer } from "../../components/BlockRenderer";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";

const AdmissionContent = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData(JNTUK_API_Urls.Admissions, setData);
  }, []);

  return (
    <React.Fragment>
      <SiteBreadcrumb pageName="Admissions" />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 md-mb-30">
              <div className="about-intro">
               <AcademicsMenu/>
                <SideBar />
              </div>
            </div>
            <div className="col-lg-6 pl-20 md-pl-14">
              <div className="content-wrapper">
                <h3 className="heading" title={data?.data?.attributes?.Page_Heading}>
                  {data?.data?.attributes?.Page_Heading}
                </h3>
                <div className="content-section">
                  <BlocksRenderer
                    content={data?.data?.attributes?.Body || []}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 pl-40 md-pl-14">
                  <RightSidebar />
                </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default AdmissionContent;
