import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AcademicsMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { BlocksRenderer } from "../../components/BlockRenderer";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";

const getChildPath = (type, setPath) => {
  let text = "Research";
  if (!type) {
    setPath({ pageCategory: "", pageName: "Research" });
    return null;
  }
  if (type === "Innovation_Ecosystem") text = "Innovation Ecosystem";
  else if (type === "Innovations_and_Extension")
    text = "Innovations and Extension";
  else if (type === "Promotion_of_Research_and_Facilities")
    text = "Promotion of Research and Facilities";
  else if (type === "Regulations_and_Guidelines")
    text = "Regulations and Guidelines";
  else if (type === "Research_Branches") text = "Research Branches";
  setPath({
    pageCategory: { label: "Research", path: "phd-courses" },
    pageName: text,
  });
  return null;
};

const ResearchContent = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [path, setPath] = useState({ pageCategory: "", pageName: "Research" });
  const { type } = useParams();
  useEffect(() => {
    getChildPath(type, setPath);
    fetchData(JNTUK_API_Urls.Research, setData);
    fetchData(JNTUK_API_Urls["Research Branches"], setData2);
  }, [type]);

  return (
    <React.Fragment>
      <SiteBreadcrumb
        pageCategory={path.pageCategory}
        pageName={path.pageName}
      />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 md-mb-30">
              <div className="about-intro">
                <AcademicsMenu />
                <SideBar />
              </div>
            </div>
            <div className="col-lg-6 pl-20 md-pl-14">
              <div className="content-wrapper">
                <div className="content-section">
                  <h3 style={{ marginBottom: "20px" }} title="Research">
                    Research
                  </h3>
                  {(!type ||
                    type === "Promotion_of_Research_and_Facilities") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes
                          ?.Promotion_of_Research_and_Facilities || []
                      }
                    />
                  )}
                  {(!type || type === "Innovations_and_Extension") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes?.Innovations_and_Extension || []
                      }
                    />
                  )}
                  {(!type || type === "Innovation_Ecosystem") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes?.Innovation_Ecosystem || []
                      }
                    />
                  )}

                  {(!type || type === "Research_Branches") && (
                    <>
                      <h3 className="heading" title="Research Branches">
                        Research Branches
                      </h3>
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Branches</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data2?.data?.map((obj, index) => (
                            <tr>
                              <td>{index + 1}.</td>
                              <td> {obj?.attributes?.Branche}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                  {(!type || type === "Regulations_and_Guidelines") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes?.Regulations_and_Guidelines || []
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-3 pl-40 md-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default ResearchContent;
