import React from "react";
import { Link } from "react-router-dom";
import { useFontSize } from "../../../FontSizeContext";
import naaclogo from "../../../assets/img/logo/naac-logo.png";
import { imageBaseURL } from "../../../utils/fetchData";
import { JNTUK_PDF_Links } from "../../../utils/api.config";

const TopHeader = (props) => {
  const { fontSize, increaseFontSize, decreaseFontSize, resetFontSize } =
    useFontSize();
  const { topBarClass } = props;

  const date = () => {
    return new Date().toLocaleString("en-IN", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  return (
    <div
      className={
        topBarClass ? topBarClass : "topbar-area home8-topbar hidden-md"
      }
    >
      <style>
        {`
           .full-width-header .rs-header .topbar-area .topbar-contact li div.naac-accreditation > strong {
              font-size: calc(${fontSize}px - 4px);
            }
            .full-width-header .rs-header .topbar-area .topbar-contact li div.naac-accreditation .naac-accreditation-content p{
              font-size: calc(${fontSize}px - 2px);
            }
        `}
      </style>
      <div className="container-fluid">
        <div className="row y-middle">
          <div className="col-md-4">
            <ul className="topbar-contact">
              <li>
                <span>{date()}</span>
              </li>
              <li>
                <div className="d-flex align-items-center naac-accreditation">
                  <strong className="position-relative">
                    Accredited 'A+' Grade by NAAC
                  </strong>
                  <div className="naac-accreditation-content">
                    <img src={naaclogo} title="NAAC Logo" alt="NAAC Logo" />
                    <p>
                      <strong>
                        JNTUK (Jawaharlal Nehru Technological University,
                        Kakinada)
                      </strong>{" "}
                      has achieved a remarkable milestone by securing an{" "}
                      <b>'A+'</b> grade from{" "}
                      <strong>
                        NAAC (National Assessment and Accreditation Council)
                      </strong>{" "}
                      at the national level. This prestigious accreditation
                      underscores JNTUK's commitment to academic excellence,
                      research, and overall institutional quality. The 'A+'
                      grade is a testament to the university's unwavering
                      dedication to providing high-quality education and
                      fostering a conducive environment for innovation and
                      learning. This achievement not only reflects JNTUK's
                      standing as a premier institution but also reinforces its
                      role in shaping future leaders and contributing
                      significantly to the advancement of education on a
                      national scale.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="col nav-bar-top">
            <ul className="topbar-right">
              <li className="login-register">
                <Link to="/category/Notifications-and-Circulars">
                  NOTIFICATION CENTER
                </Link>
              </li>
              <li className="login-register">
                <a
                  href={"https://ecosak.org.in"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ALUMNI
                </a>
              </li>
              <li className="login-register">
                <a
                  href={"https://online.jntuksdc.co.in"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  STUDENT CORNER
                </a>
              </li>
              <li className="login-register">
                <Link to="/gallery/academic-events">NEWS & EVENTS</Link>
              </li>
              <li className="login-register">
                <a
                  href={`${imageBaseURL}${JNTUK_PDF_Links.Directory_Pdf}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DIRECTORY
                </a>
              </li>
              <li className="login-register">
                <a
                  href="https://mail.google.com/a/jntuk.edu.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  JNTUK EMAIL
                </a>
              </li>
            </ul>
            <button onClick={decreaseFontSize}>A-</button>
            <button onClick={resetFontSize}>A</button>
            <button onClick={increaseFontSize}>A+</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
