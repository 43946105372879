import React  from "react";
import { AboutMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";

import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";


const Content = () => {

  return (
    <React.Fragment>
      <SiteBreadcrumb
        pageName="Location Map"
      />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 md-mb-30">
              <div className="about-intro">
                <AboutMenu />
                <SideBar />
              </div>
            </div>

            <div className="col-lg-6 pl-20 md-pl-14">
              <div className="content-wrapper">
                <div className="content-section">
                <h2 title="Location Map">Location Map</h2>
                <iframe title="Location Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d238.49542931082723!2d82.24262115491288!3d16.97814717257171!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a38299cdd8f2049%3A0xb7251fd704659869!2sJawaharlal%20Nehru%20Technological%20University%2C%20Kakinada!5e0!3m2!1sen!2sin!4v1706180831314!5m2!1sen!2sin"
                width="100%"
                height="600"
                allowFullScreen=""
                aria-hidden="false"
              ></iframe>
                </div>
              </div>
            </div>
            <div className="col-lg-3 pl-40 md-pl-14">
                  <RightSidebar />
                </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default Content;
