import React, { useState, useEffect } from "react";
import { AccreditationMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";

import AccreditationReportShort from "../../components/AccreditationReport/AccreditationReportShort";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";
import { fetchData } from "../../utils/fetchData";
import { JNTUK_API_Urls } from "../../utils/api.config";
import Pagination from "../../components/Pagination";
import { BlocksRenderer } from "../../components/BlockRenderer/BlocksRenderer";
import { formatDate } from "../../utils/methods";

const Content = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const totalItems = data?.meta?.pagination?.total;
  useEffect(() => {
    fetchData(JNTUK_API_Urls.NAAC_Reports, setData);
  }, []);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <React.Fragment>
      <SiteBreadcrumb pageName="NAAC Reports" />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 md-mb-30">
              <div className="about-intro">
                <AccreditationMenu />
                <SideBar />
              </div>
            </div>

            <div className="col-lg-6 pl-20 md-pl-14">
              <div className="content-wrapper">
                <div className="content-section">
                  <h2 title="NAAC Report">NAAC Reports</h2>
                  <div className="overflow-x">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col" style={{ width: "100px" }}>
                            Date
                          </th>
                          <th scope="col">
                            Description
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data?.map((obj, index) => (
                          <tr key={(currentPage - 1) * itemsPerPage + index + 1}>
                            <td style={{ color: "#ff5421", fontWeight: '700' }}>
                              {(
                                (currentPage - 1) * itemsPerPage +
                                index +
                                1
                              )
                                .toString()
                                .padStart(2, "0")}
                              .
                            </td>
                            <td style={{ width: "100px", fontWeight: "700" }}>
                              {formatDate(obj?.attributes?.Publish_Date)}
                            </td>
                            <td>
                              <BlocksRenderer content={obj?.attributes?.Body || []} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    paginate={paginate}
                    arrayLength={totalItems / itemsPerPage}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 pl-40 md-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default Content;
