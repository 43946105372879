import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import NotificationScroll from "./NotificationScrollSection";
import SectionTitle from "../../components/Common/SectionTitle";
import EventsShort from "../../components/Events/EventsShort";

// Images
import eventImage from "../../assets/img/event/india-university.jpg";
import { fetchData } from "../../utils/fetchData";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { formatDate } from "../../utils/methods";
function getUrl(inputString) {
  let stringWithUnderscores = inputString.replace(/ /g, "-");
  stringWithUnderscores = stringWithUnderscores.replace(/&/g, "_");

  return stringWithUnderscores;
}
const Events = () => {
  const marqueeRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filter, setFilter] = useState("Notifications and Circulars");
  const [notifications, setNotifications] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startScrollLeft, setStartScrollLeft] = useState(0);
  const [resetScroll, setResetScroll] = useState("");

  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData(JNTUK_API_Urls.Live_Scrolling, setData);
  }, []);
  useEffect(() => {
    if (filter === "Tenders") {
      fetchData(JNTUK_API_Urls.Tenders, setNotifications);
    } else {
      fetchData(
        `/notifications?sort=Publish_Date:desc&populate=*&filters[notification_categories][Notification_Category][$contains]=${filter}`,
        setNotifications
      );
    }
  }, [filter]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX);
    setStartScrollLeft(marqueeRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - startX;
      marqueeRef.current.scrollLeft = startScrollLeft - deltaX;
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  const handleIndexClick = (index) => {
    setCurrentIndex(index);
    if (handleMouseEnter) {
      scrollToIndex(index);
    }
  };

  const scrollToIndex = (index) => {
    const totalWidth = marqueeRef.current.scrollWidth;
    const scrollAmount = (index * totalWidth) / items.length;
    marqueeRef.current.scrollTo({ left: scrollAmount, behavior: "smooth" });
  };

  useEffect(() => {
    AOS.init();
    const marqueeContainer = marqueeRef.current;
    let intervalId;

    const scroll = () => {
      if (!isPaused && marqueeContainer) {
        marqueeContainer.scrollLeft += 1;
        if (
          marqueeContainer.scrollLeft >=
          marqueeContainer.scrollWidth - marqueeContainer.clientWidth
        ) {
          marqueeContainer.scrollLeft = 0;
        }
      }
    };

    intervalId = setInterval(scroll, 20);

    return () => {
      clearInterval(intervalId);
    };
  }, [isPaused]);

  const originalItems = (data?.data || []).map((data, index) => (
    <EventsShort
      key={index}
      eventsClass="events-short d-flex align-items-start"
      dateboxClass="date-part"
      fullDate={formatDate(data?.attributes?.Start_Date)}
      cat1="Math"
      cat2="English"
      slNo={(index + 1).toString().padStart(2, "0")}
      title={data}
      slNoClass="slNo"
    />
  ));
  const clonedItems = originalItems.map((originalItem, index) =>
    React.cloneElement(originalItem, {
      key: `cloned-${index}`,
      eventsClass: `${originalItem.props.eventsClass} cloned-item`,
    })
  );
  const items = originalItems.concat(clonedItems);

  const originalNotificationItems = (notifications?.data || []).map(
    (data, index) => (
      <EventsShort
        key={index}
        eventsClass="events-short d-flex align-items-start mb-5"
        dateboxClass="date-part"
        fullDate={formatDate(data?.attributes?.Publish_Date)}
        cat1="Math"
        cat2="English"
        slNo={(index + 1).toString().padStart(2, "0")}
        title={data}
        slNoClass="slNo"
      />
    )
  );

  const nclonedItems = originalNotificationItems.map(
    (originalNotificationItem, index) =>
      React.cloneElement(originalNotificationItem, {
        key: `ncloned-${index}`,
        eventsClass: `${originalNotificationItem.props.eventsClass} cloned-item`,
      })
  );
  const notificationItems = originalNotificationItems.concat(nclonedItems);

  const indexItems = Array.from({ length: 8 }, (_, index) => (
    <span
      key={index}
      className={`index-item ${currentIndex === index ? "active" : ""}`}
      onClick={() => handleIndexClick(index)}
    >
      {index + 1}
    </span>
  ));

  const handleResetScroll = (filName) => {
    setResetScroll(filName);
  };

  return (
    <div className="rs-latest-events style1 bg-wrap pt-30 pb-30 md-pb-30">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-30 md-mb-30 zIndex1"
          subtitleClass=""
          subtitle=""
          titleClass="sub-title mb-0 orange text-capitalize"
          title="Notification Center"
        />
        <div className="position-relative zIndex1">
          <div
            className="index-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {indexItems}
            <Link
              className="viewall-notification"
              to="/category/Live-Scrolling"
            >
              View All
            </Link>
          </div>
          <div className="liveScroll">
            <div className="ls-label">Live Scrolling</div>
            <div
              className="ls-area event-wrap"
              ref={marqueeRef}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="rs-team style1 d-flex flex-nowrap">{items}</div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-5 pr-50 md-pt-0 md-pr-14 md-mb-30">
            <div className="single-img">
              <img
                src={eventImage}
                alt="Notification Center"
                title="Notification Center"
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="d-flex notification-filter">
              <button
                className={
                  filter === "Notifications and Circulars" ? "active" : ""
                }
                onClick={() => {
                  setFilter("Notifications and Circulars");
                  handleResetScroll("Notifications and Circulars");
                }}
              >
                Notifications & Circulars
              </button>
              <button
                className={filter === "Tenders" ? "active" : ""}
                onClick={() => {
                  setFilter("Tenders");
                  handleResetScroll("Tenders");
                }}
              >
                Tenders
              </button>
              <button
                className={filter === "Sports" ? "active" : ""}
                onClick={() => {
                  setFilter("Sports");
                  handleResetScroll("Sports");
                }}
              >
                Sports
              </button>
              <button
                className={filter === "Workshops" ? "active" : ""}
                onClick={() => {
                  setFilter("Workshops");
                  handleResetScroll("Workshops");
                }}
              >
                Workshops
              </button>
              <button
                className={filter === "Conferences" ? "active" : ""}
                onClick={() => {
                  setFilter("Conferences");
                  handleResetScroll("Conferences");
                }}
              >
                Conferences
              </button>
              <button
                className={filter === "Results" ? "active" : ""}
                onClick={() => {
                  setFilter("Results");
                  handleResetScroll("Results");
                }}
              >
                Results
              </button>
            </div>
            <div className="event-wrap">
              {notificationItems?.length === 0 && (
                <h4
                  style={{ marginTop: "20px" }}
                  title={`Notifications are not available in ${filter}`}
                >
                  Notifications are not available in {filter}.
                </h4>
              )}
              <NotificationScroll
                items={notificationItems}
                resetScroll={resetScroll}
              />
            </div>
            <div className="text-end position-relative z-1 mt-10">
              <Link
                className="readon orange-btn main-home text-capitalize"
                to={`category/${getUrl(filter)}`}
              >
                View All
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
