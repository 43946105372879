const currentDate = new Date();
const formattedDate = currentDate.toISOString();
export const JNTUK_API_Urls = {
  "Site-Map": "/site-maps",
  About_JNTUK: "/about-jntuk?populate=*",
  Institutional_Body: "/institutional-body",
  JNTUK_Vision: "/jntuk-vision",
  Download_Application_Formats: "/download-application-formats?populate=*",
  SDD: "/sdd",
  SWOC: "/swoc",
  Chancellor: "/chancellor?populate=*",
  Vice_Chancellor: "/vice-chancellor?populate=*",
  "SC_And_ST CELL":
    "/sc-and-st-cell?populate[0]=sc_and_st_cell_user&populate[1]=sc_and_st_cell_user.Image&populate[2]=Gallery",
  Former_Vice_Chancellors: "/former-vice-chancellors?sort=SrNo:asc&populate=*",
  Rector: "/rector?populate=*",
  Registrar: "/registrar?populate=*",
  OSD: "/osd?populate=*",
  Tenders: "/tenders?sort=Publish_Date:desc&populate=*",
  Press_Releases: "/press-releases?sort=Publish_Date:desc&populate=*",
  Latest_Events: "/latest-events?sort=Publish_Date:desc&populate=*",
  Executive_Council: "/jntuk-executive-councils?populate=*&sort=createdAt:asc",
  Academic_Senate: "/jntuk-academic-senates?sort=Srno:asc&populate=*",
  Board_of_Studies: "/jntuk-board-of-studies?populate=*",
  Directorates: "/jntuk-directorates?populate=*",
  Special_Officers: "/jntuk-special-officers?populate=*",
  Infrastructure_and_Learning_Resources:
    "/jntuk-infrastructure-and-learning-resource",
  Methodology: "/methodology",
  Admissions: "/jntuk-admission",
  Teaching_Learning_Process: "/jntuk-teaching-learning-process",
  Student_Support_and_Progression: "/jntuk-student-support-and-progression",
  JNTUK_Contacts: "/jntuk-contact",
  Autonomous_Colleges: "/jntuk-autonomous-colleges",
  Governing_Hierarchy: "/jntuk-governing-hierarchy?populate=*",
  Research: "/research",
  "Research Branches": "research-branches",
  Circular_Aspects: "/jntuk-circular-aspect",
  University_Gallery: "/university-galleries?populate=*",
  Campus_Buildings: "/jntuk-campus-building?populate=*",
  Academic_Events: "/academic-events?populate=*",
  Festivals: "/festivals?populate=*",
  University_Celebrations: "/university-celebrations?populate=*",
  Sports_Events: "/sports-events?populate=*",
  Convocations: "/jntuk-convocations?sort=Date_of_Convocation:asc&populate=*",
  JNTUK_PDF_Files_Uploads: "/jntuk-pdf-files-uploads",
  University_Examination_Fee_Structure: "/university-examination-fee-structure",
  Affiliated_Colleges_East_Godavari:
    "/affiliated-colleges?filters[College_Category][$contains]=Affiliated Colleges – East Godavari",
  Affiliated_Colleges_West_Godavari:
    "/affiliated-colleges?filters[College_Category][$contains]=Affiliated Colleges – West Godavari",
  Affiliated_Colleges_Guntur:
    "/affiliated-colleges?filters[College_Category][$contains]=Affiliated Colleges – Guntur",
  Affiliated_Colleges_Krishna:
    "/affiliated-colleges?filters[College_Category][$contains]=Affiliated Colleges – Krishna",
  Affiliated_Colleges_Prakasam:
    "/affiliated-colleges?filters[College_Category][$contains]=Affiliated Colleges – Prakasam",
  Affiliated_Colleges: "/affiliated-colleges",
  Live_Scrolling: `/live-scrollings?filters[Start_Date][$lte]=${formattedDate}&filters[End_Date][$gte]=${formattedDate}&sort=Position:asc&sort=Start_Date:desc&populate=*`,
  University_Song: "/university-song?populate=*",
  Addl_Controller_of_Examinations:
    "/addl-controller-of-examinations?populate=*&sort=createdAt:asc",
  Controller_of_Examination_UG: "/controller-of-examination-ug?populate=*",
  Controller_of_Examinations_PG_and_SDC:
    "/controller-of-examinations-pg-and-sdc?populate=*",
  Programmes_Offered: "/programmes-offereds",
  Home_Page_Banner: `/home-page-banners?populate=*&filters[End_Time][$gte]=${formattedDate}&sort=createdAt:asc`,
  Under_Graduate: "/courses?filters[Degree][$contains]=Under Graduate",
  Post_Graduate: "/courses?filters[Degree][$contains]=Post Graduate",
  JNTUK_Directorates: "/jntuk-directorates?populate=*",
  JNTUK_DAP_Department:
    "/jntuk-dap-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DAA_Department:
    "/jntuk-dap-director-audit?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DE_Department:
    "/jntuk-dap-director-evaluation?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DA_Department:
    "/jntuk-dap-director-admission?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DIIIPT_Department:
    "/jntuk-diiitp-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DRD_Department:
    "/jntuk-drd-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_FDC_Department:
    "/jntuk-fdc-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DID_Department:
    "/jntuk-did-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_FUR_Department:
    "/jntuk-fur-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DIST_Department:
    "/jntuk-dist-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_SOMS_Department:
    "/jntuk-sms-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DIQACLC_Department:
    "/jntuk-diqaclc-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_Government_Affairs_Department:
    "/director-legal-and-govt-affair?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_IT_Infrastructure_Department:
    "/jntuk-it-infrastructure-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_Research_Innovation_Department:
    "/jntuk-research-innovation-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DEWG_Department:
    "/jntuk-dewg-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DBICS_Department:
    "/jntuk-dbics-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DODL_OLP_Department:
    "/jntuk-dodl-olp-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DGCI_Department:
    "/jntuk-dgci-director?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DUGAS_Department:
    "/jntuk-dugas?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_DI_Department:
    "/jntuk-di-department?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image&populate[3]=Right_Image1&populate[4]=Right_Image2&populate[5]=jntuk_directorate.Download_Bio_Data&populate[6]=jntuk_directorate.Download_Profile_Data",
  JNTUK_UCEK_Principal:
    "/jntuk-ucek-principal?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image",
  JNTUK_UCEN_Principal:
    "/jntuk-ucen-principal?populate[0]=Gallery&populate[1]=jntuk_directorate&populate[2]=jntuk_directorate.Image",
  DAA_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Academic Audit",
  DAP_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Academic Planning",
  DE_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Director of Evaluation",
  DA_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Admissions",
  DIIIP_Training_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=IIIP & Training",
  DRD_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Research and Development",
  FDC_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Faculty Development Cell",
  DID_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Infrastructure Development",
  FUR_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Foreign University Relations",
  DIST_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Institute of Science and Technology",
  SOMS_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=School of Management Studies",
  DIQACLC_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=IQAC, and University Legal Cell",
  DEWG_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Empowerment of Women & Grievances",
  DBICS_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=BICS",
  DODL_OLP_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=ODL & OL Programmes",
  DGCI_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=Green Campus Initiatives",
  UCEK_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=University College of Engineering Kakinada",
  UCEN_Notifications:
    "/notifications?sort=Publish_Date:desc&populate=notification_department&filters[notification_department][Department][$contains]=University College of Engineering Narasaraopeta",
  Notification_Categories: "/notification-categories",
  NAAC_Reports: "/naac-reports",
};

export const JNTUK_PDF_Links = {
  JNTU_Act_Link: "/uploads/JNTUACT_8fa174ad54.pdf",
  RTI_Act_Link: "/uploads/RTI_Information_b80fd5bb48.pdf",
  Anti_Ragging_Link:
    "/uploads/website_homepage_antiragging_information_1_2_48a29fbf84.docx",
  AICTE_Approvals: "/uploads/AICTE_Approvals_3028db5cfb.pdf",
  PCI_Approvals: "/uploads/PCI_Approval_817f71a467.pdf",
  JNTUK_NAAC_SSR_2023: "/uploads/JNTUKNAACSSR_2023_61a6c8b203.pdf",
  NIRF_Overall_Report_2023:
    "/uploads/Jawaharlal_Nehru_Technological_University_Kakinada_NIRF_OVERALL_e0e902f9b8.pdf",
  JNTUK_AQAR_2017_18: "/uploads/JNTUK_AQAR_2017_18_FINAL_d430c4ad91.pdf",
  NIRF_Overall_Report_2022:
    "/uploads/NIRF_OVERALL_DATA_JNTU_20220218_e910d16040.pdf",
  NAAC_Self_Study_Report_Feb_2017:
    "/uploads/NAAC_JNTU_KAKINADA_SELF_STUDY_REPORT_SSR_Feb_2017_3820479a3e.pdf",
  Annual_Quality_Assurance_Reports:
    "/uploads/JNTUK_AQAR_2019_20_4_003aa1362e.pdf",
  ARAQ_Of_Student_Satisfaction_Survey:
    "/uploads/JNTUK_student_satisfaction_survey_e84ee1cecc.pdf",
  National_Institutional_Rankings_Framework_2020:
    "/uploads/JNTUK_NIRF_Overall_data_2019_20_b12637205f.pdf",
  "2F_of_UGC": "/uploads/2f_of_UGC_4c14d4b3fe.pdf",
  JNTUK_MALPRACTICE_GUIDELINES:
    "/uploads/JNTUK_Malpractices_guidelines_0bc4147844.pdf",
  PHD_AWARDEES_LIST_2016_2019:
    "/uploads/1575382865014_Ph_D_Awardees_2016_2019_b3fc9c2ba6.pdf",
  "12B_of_UGC": "/uploads/12_B_of_UGC_f8add22004.pdf",
  IT_Policies:
    "/uploads/JNTUK_IT_policies_and_procedures_Manual_with_images_af5cbdbe06.pdf",
  Sexual_Harassment_Committees: "/uploads/20230426105917_9e1e12a2aa.pdf",
  Placements_Graph: "/uploads/Placement_graph_315abed287.pdf",
  Visits_to_Industry: "/uploads/Visit_to_Coromandel_21accd4f09.pdf",
  Lab_Openings: "/uploads/Lab_Openings_4310d2e055.pdf",
  Skill_Development_Programmes:
    "/uploads/Summer_Internship_Program_Siemens_coe_0a3d81f4a6.pdf",
  Directory_Pdf: "/uploads/Administration_9e9bd6adec.pdf",
  JNTUK_Newsletter:
    "/uploads/JNTUK_News_Letter_I_Edition_2023_1_6870bef94c.pdf",
};
