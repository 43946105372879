import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SingleTeam from "../../components/Team/SingleTeam";
import SectionTitle from "../../components/Common/SectionTitle";
import { fetchData, imageBaseURL } from "../../utils/fetchData";
import { JNTUK_API_Urls } from "../../utils/api.config";

const Happenings = () => {
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState(null);
  const [data4, setData4] = useState(null);
  useEffect(() => {
    fetchData(JNTUK_API_Urls.Academic_Events, setData1);
    fetchData(JNTUK_API_Urls.Festivals, setData2);
    fetchData(JNTUK_API_Urls.University_Celebrations, setData3);
    fetchData(JNTUK_API_Urls.Sports_Events, setData4);
  }, []);
  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        type="button"
        onClick={onClick}
        className={className}
        title="Next"
        aria-label="Next"
      >
        <i className="fa fa-chevron-right"></i>
      </button>
    );
  }

  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        type="button"
        onClick={onClick}
        className={className}
        title="Previous"
        aria-label="Previous"
      >
        {" "}
        <i className="fa fa-chevron-left"></i>
      </button>
    );
  }

  const slilderSettings = {
    dots: false,
    centerMode: false,
    autoplay: true,
    infinite: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "active",
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="rs-team happenings-bg style1 nav-style2">
        <div className="container">
          <div className="row y-middle position-relative">
            <div className="col-lg-4 pt-30 lg-pt-40 pb-30">
              <div className="sec-title">
                <h2 className="sub-title orange" title="JNTUK">
                  JNTUK
                </h2>
              </div>
              <SectionTitle
                sectionClass="sec-title"
                subtitleClass=""
                subtitle=""
                titleClass="title mb-0 white"
                title="Happenings"
                descClass="desc mt-20 mt-md-40"
                description="Dive into the vibrant world of JNTUK through our Happenings on the website. Experience real-time updates on events, achievements, and campus life, offering a comprehensive glimpse into the dynamic spirit of our JNTUK community."
              />
              <div className="text-left mt-20">
                <Link
                  className="readon orange-btn main-home text-capitalize"
                  to="/gallery/academic-events"
                >
                  View All
                </Link>
              </div>
            </div>
            <div className="col-lg-7 offset-lg-1 pt-80 lg-pt-40 pb-40 sm-mb-30">
              <div className="pull-down">
                <Slider {...slilderSettings}>
                  {[data1, data2, data3, data4]?.map((obj) => (
                    <Link
                      to={{
                        pathname: "/gallery-all",
                        state: {
                          title: obj?.data[0]?.attributes?.Gallery_Title || "",
                          images: obj?.data[0]?.attributes?.Images,
                          type:obj?.data[0]?.attributes?.Gallery_Title || "",
                        },
                      }}
                    >
                      <SingleTeam
                        itemClass="team-item"
                        Image={
                          `${imageBaseURL}${obj?.data[0]?.attributes?.Images?.data[0]?.attributes?.url}` ||
                          ""
                        }
                        Title={obj?.data[0]?.attributes?.Gallery_Title || ""}
                        Designation=""
                      />
                    </Link>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Happenings;
