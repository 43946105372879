import React, { useState } from "react";
import axios from "../../utils/axios.config";
import { JNTUK_API_Urls } from "../../utils/api.config";
const initialValues = {
  data: {
    College_Code: "",
    College_Name: "",
    College_Email: "",
    Courses_Offered: "",
    College_Url: "",
    College_Category: "Affiliated Colleges – East Godavari",
    Address: "",
    Intake: "",
    publishedAt: null,
  },
};
const ContactForm = (props) => {
  const { submitBtnClass, btnText } = props;
  const [payload, setPayload] = useState(initialValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPayload((prevPayload) => ({
      data: {
        ...prevPayload.data,
        [name]: value,
      },
    }));
  };
  const validateForm = () => {
    if (!payload.data.College_Category.trim()) {
      alert("College Category is required");
      return false;
    }

    if (!payload.data.College_Code.trim()) {
      alert("College Code is required");
      return false;
    }
    if (!payload.data.College_Email.trim()) {
      alert("College Email is required");
      return false;
    }
    if (!payload.data.College_Name.trim()) {
      alert("College Name is required");
      return false;
    }
    if (!payload.data.Intake.trim()) {
      alert("Intake is required");
      return false;
    }
    if (!payload.data.College_Url.trim()) {
      alert("College Website Url is required");
      return false;
    }
    if (!payload.data.Courses_Offered.trim()) {
      alert("Courses Offered is required");
      return false;
    }
    if (!payload.data.Address.trim()) {
      alert("Address is required");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      axios
        .post(JNTUK_API_Urls.Affiliated_Colleges, payload)
        .then(() => {
          alert("College Registration is Successful.");
        })
        .catch(() => {
          alert("Something went wrong in College Registration.");
        });
    }
  };
  return (
    <form id="contact-form" action="#">
      <div className="row">
        <div className="col-md-6 mb-30">
          <select
            id="College_Category"
            name="College_Category"
            className="from-control"
            value={payload.data.College_Category}
            onChange={handleInputChange}
            required
          >
            <option value="Affiliated Colleges – East Godavari">
              Affiliated Colleges – East Godavari
            </option>
            <option value="Affiliated Colleges – West Godavari">
              Affiliated Colleges – West Godavari
            </option>
            <option value="Affiliated Colleges – Krishna">
              Affiliated Colleges – Krishna
            </option>
            <option value="Affiliated Colleges – Guntur">
              Affiliated Colleges – Guntur
            </option>
            <option value="Affiliated Colleges – Prakasam">
              Affiliated Colleges – Prakasam
            </option>
          </select>
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="collegeCode"
            name="College_Code"
            placeholder="College Code"
            required
            value={payload.data.College_Code}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="collegeEmail"
            name="College_Email"
            placeholder="College Email"
            required
            value={payload.data.College_Email}
            onChange={handleInputChange}
          />
        </div>
        {/* <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="password"
            name="password"
            placeholder="Password"
            required
          />
        </div> */}
        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="collegeName"
            name="College_Name"
            placeholder="College Name"
            required
            value={payload.data.College_Name}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="intake"
            name="Intake"
            placeholder="Intake"
            required
            value={payload.data.Intake}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="collegeUrl"
            name="College_Url"
            placeholder="College Website Url"
            required
            value={payload.data.College_Url}
            onChange={handleInputChange}
          />
        </div>

        <div className="col-12 mb-30">
          <textarea
            className="from-control"
            id="coursesOffered"
            name="Courses_Offered"
            placeholder="Courses offered"
            required
            value={payload.data.Courses_Offered}
            onChange={handleInputChange}
          ></textarea>
        </div>

        <div className="col-12 mb-30">
          <textarea
            className="from-control"
            id="address"
            name="Address"
            placeholder="Address"
            required
            value={payload.data.Address}
            onChange={handleInputChange}
          ></textarea>
        </div>
      </div>
      <div className="btn-part">
        <button
          onClick={handleSubmit}
          className={
            submitBtnClass ? submitBtnClass : "readon learn-more submit"
          }
          type="submit"
        >
          {btnText ? btnText : "Submit Now"}
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
