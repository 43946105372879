import React from "react";
import { Link, useLocation } from "react-router-dom";
import { imageBaseURL } from "../../../utils/fetchData";
import { JNTUK_PDF_Links } from "../../../utils/api.config";

const items = [
  {
    label: "About",
    children: [
      {
        to: "/about",
        label: "About JNTUK",
        newTab: false,
      },
      {
        to: "/jntukvision",
        label: "Governance, Leadership and Management",
        newTab: false,
        children: [
          {
            to: "/jntukvision/institutional_vision_and_leadership",
            label: "Institutional Vision and Leadership",
            newTab: false,
          },
          {
            to: "/jntukvision/core_values",
            label: "Core Values",
            newTab: false,
          },
          {
            to: "/jntukvision/institutional_values_and_best_practices",
            label: "Institutional Values and Best Practices",
            newTab: false,
          },
        ],
      },
      {
        to: "/SWOC",
        label: "SWOC",
        newTab: false,
        children: [
          {
            to: "/SWOC/institutional_strength",
            label: "Institutional Strength",
            newTab: false,
          },
          {
            to: "/SWOC/institutional_weakness",
            label: "Institutional Weakness",
            newTab: false,
          },
          {
            to: "/SWOC/institutional_opportunity",
            label: "Institutional Opportunity",
            newTab: false,
          },
          {
            to: "/SWOC/institutional_challenge",
            label: "Institutional Challenge",
            newTab: false,
          },
        ],
      },
      {
        to: "/SDD",
        label: "Strategy Development and Development",
        newTab: false,
      },
      {
        to: "/institutional-bodies",
        label: "Institutional Bodies",
        newTab: false,
      },
      {
        to: `${imageBaseURL}${JNTUK_PDF_Links.JNTU_Act_Link}`,
        label: "JNTU Act",
        newTab: true,
        pdfLink: true,
      },
      {
        to: `${imageBaseURL}${JNTUK_PDF_Links.RTI_Act_Link}`,
        label: "RTI Act",
        newTab: true,
        pdfLink: true,
      },
      {
        to: `https://docs.google.com/gview?url=${imageBaseURL}${JNTUK_PDF_Links.Anti_Ragging_Link}&embedded=true`,
        label: "Anti Ragging",
        newTab: true,
        pdfLink: true,
      },
      {
        to: "/university-song",
        label: "University Song",
        newTab: false,
      },
      {
        to: "/category/Press-Releases",
        label: "Media/Press",
        newTab: false,
      },
      {
        to: "/jntuk-contacts",
        label: "Contacts",
        newTab: false,
      },
      {
        to: "/gallery/univ-gallery",
        label: "Univ Gallery",
        newTab: false,
      },
      {
        to: "/convocations",
        label: "Convocations",
        newTab: false,
      },
      {
        to: "/category/Tie-ups-and-Mou’s",
        label: "Tie Ups & MOU's",
        newTab: false,
      },
      {
        to: "/governing-hierarchy",
        label: "Governing Heirarchy",
        newTab: false,
      },
      {
        to: "/location-map",
        label: "Location Map",
        newTab: false,
      },
    ],
  },
  {
    label: "Administration",
    children: [
      {
        to: "/profile/chancellor",
        label: "Chancellor",
        newTab: false,
      },
      {
        to: "/profile/vice-chancellor",
        label: "Vice Chancellor",
        newTab: false,
      },
      {
        to: "/profile/rector",
        label: "Rector",
        newTab: false,
      },
      {
        to: "/profile/registrar",
        label: "Registrar",
        newTab: false,
      },
      {
        to: "/profile/osd",
        label: "OSD",
        newTab: false,
      },
      {
        to: "/profile/executive-council",
        label: "Executive Council",
        newTab: false,
      },
      {
        to: "/academic-senate",
        label: "Academic Senate",
        newTab: false,
      },
      {
        to: "/board-of-studies",
        label: "Board Of Studies",
        newTab: false,
      },
      {
        to: "/jntuk-vice-chancellor",
        label: "JNTUK Vice Chancellor's",
        newTab: false,
      },
      {
        to: "/directorates",
        label: "Directorates",
        newTab: false,
        children: [
          {
            to: "/profile/academics-and-planning-dap",
            label: "Director, Academic Planning",
            newTab: false,
          },
          {
            to: "/profile/director-academic-audit",
            label: "Director, Academic Audit",
            newTab: false,
          },
          {
            to: "/profile/director-of-evaluation-de",
            label: "Director Of Evaluation",
            newTab: false,
          },
          {
            to: "/profile/admissions-da",
            label: "Director, Admissions",
            newTab: false,
          },
          {
            to: "/profile/director-research-and-development",
            label: "Director, Research And Development",
            newTab: false,
          },
          {
            to: "/profile/director-libraryn",
            label: "Director, ODL & OL Programmes",
            newTab: false,
          },
          {
            to: "/profile/faculty-development-centre",
            label: "Director, Faculty Development Centre",
            newTab: false,
          },

          {
            to: "/profile/infrastructure-development",
            label: "Chief Engineer & Director, Infrastructure",
            newTab: false,
          },
          {
            to: "/profile/director-foreign-university-relations-dfur",
            label: "Director, Foreign University Relations",
            newTab: false,
          },
          {
            to: "/profile/institute-of-science-and-technology-ist",
            label: "Director, IST",
            newTab: false,
          },
          {
            to: "/profile/internal-quality-assurance-cell",
            label: "Director, IQAC",
            newTab: false,
          },
          {
            to: "/profile/school-of-management-studies",
            label: "Director - School Of Management Studies",
            newTab: false,
          },
          {
            to: "/profile/director-industry-institute-interaction-placements-and-training",
            label: "Director, IIIP & Training",
            newTab: false,
          },
          // {
          //   to: "/profile/director-green-campus-initiatives",
          //   label: "Director, Green Campus Initiatives",
          //   newTab: false,
          // },
          {
            to: "/profile/director-empowerment-of-women-grievances",
            label: "Director, Empowerment Of Women & Grievances",
            newTab: false,
          },
          // {
          //   to: "/profile/director-bics",
          //   label: "Director, BICS",
          //   newTab: false,
          // },
          {
            to: "/profile/director-legal-and-govt-affairs",
            label: "Director Legal & Government Affairs",
            newTab: false,
          },
          // {
          //   to: "/profile/director-university-games-and-sports",
          //   label: "Director(I/C) Director, University Games & Sports",
          //   newTab: false,
          // },

          // {
          //   to: "/profile/director-incubation",
          //   label: "Director(I/C) Incubation",
          //   newTab: false,
          // },
          {
            to: "/profile/director-it-infrastructure",
            label: "Director, IT Infrastructure",
            newTab: false,
          },
          {
            to: "/profile/research-innovation-centre",
            label: "Director, Research Innovation Centre",
            newTab: false,
          },
        ],
      },
      {
        to: "",
        label: "Principal's",
        newTab: false,
        children: [
          {
            to: "/profile/principal-university-college-of-engineering-kakinada",
            label: "Principal, UCEK",
            newTab: false,
          },
          {
            to: "/profile/principal-university-college-of-engineering-narasaraopeta",
            label: "Principal, UCEN",
            newTab: false,
          },
        ],
      },
      {
        to: "/special-officers",
        label: "Special Officers",
        newTab: false,
      },
    ],
  },
  {
    label: "Academics",
    children: [
      {
        to: "/methodology",
        label: "Methodology",
        newTab: false,
      },
      {
        to: "/admissions",
        label: "Admission",
        newTab: false,
      },
      {
        to: "/under-graduate",
        label: "UG - Programmes Offered - Affiliated",
        newTab: false,
        children: [
          {
            to: "/course/1",
            label: "Bachelor of Technology",
            newTab: false,
          },
          {
            to: "/course/2",
            label: "Bachelor of Pharmacy",
            newTab: false,
          },
          {
            to: "/course/3",
            label: "Bachelor of Architecture",
            newTab: false,
          },
        ],
      },
      {
        to: "/post-graduate",
        label: "PG - Programmers Offered - Affiliated",
        newTab: false,
        children: [
          {
            to: "/course/4",
            label: "Pharm D",
            newTab: false,
          },
          {
            to: "/course/5",
            label: "Master of Technology",
            newTab: false,
          },
          {
            to: "/course/6",
            label: "Master of Pharmacy",
            newTab: false,
          },
          {
            to: "/course/7",
            label: "Master of Computer Applications",
            newTab: false,
          },
          {
            to: "/course/8",
            label: "Master of Bussiness Administration",
            newTab: false,
          },
          {
            to: "/course/9",
            label: "Management of Applied Management",
            newTab: false,
          },
        ],
      },
      {
        to: "/phd-courses",
        label: "Research",
        newTab: false,
        children: [
          {
            to: "/phd-courses/Promotion_of_Research_and_Facilities",
            label: "Promotion of Research and Facilities",
            newTab: false,
          },
          {
            to: "/phd-courses/Innovations_and_Extension",
            label: "Innovations and Extension",
            newTab: false,
          },
          {
            to: "/phd-courses/Innovation_Ecosystem",
            label: "Innovation Ecosystem",
            newTab: false,
          },
          {
            to: "/phd-courses/Research_Branches",
            label: "Branches",
            newTab: false,
          },
          {
            to: "/phd-courses/Regulations_and_Guidelines",
            label: "Regulations & Guidelines",
            newTab: false,
          },
        ],
      },
      {
        to: "/category/Academic-Calendars",
        label: "Academic Calendars",
        newTab: false,
      },
      {
        to: "/programmes-offered",
        label: "Programmes Offered",
        newTab: false,
      },
      {
        to: "/academicsdata",
        label: "Circular Aspects",
        newTab: false,
        children: [
          {
            to: "/academicsdata/Circular_Aspects",
            label: "Circular Aspects",
            newTab: false,
          },
          {
            to: "/academicsdata/Curriculum_Design_and_Development",
            label: "Curriculum Design and Development",
            newTab: false,
          },
          {
            to: "/academicsdata/Curriculum_Enrichment",
            label: "Curriculum Enrichment",
            newTab: false,
          },
        ],
      },
      {
        to: "/teachingLearnProcess",
        label: "Teaching-Learning Process",
        newTab: false,
      },
      {
        to: "/studentsupportprogression",
        label: "Student Support and Progression",
        newTab: false,
      },
      {
        to: "/infrastructurelearning",
        label: "Infrastructure and Learning Resources",
        newTab: false,
        children: [
          {
            to: "/infrastructurelearning/physical_facilities",
            label: "Physical Facilities",
            newTab: false,
          },
          {
            to: "/infrastructurelearning/adequate_facilities",
            label: "Adequate Facilities",
            newTab: false,
          },
          {
            to: "/infrastructurelearning/learning_resource",
            label: "Learning Resource",
            newTab: false,
          },
        ],
      },
      {
        to: "",
        label: "Constituent Colleges/Units",
        newTab: false,
        children: [
          {
            to: "https://www.jntucek.ac.in",
            label: "University College of Engineering Kakinada Campus",
            newTab: true,
            isExternal: true,
          },
          {
            to: "https://jntukucen.ac.in",
            label: "University College of Engineering Narasaraopet Campus",
            newTab: true,
            isExternal: true,
          },
          {
            to: `${process.env.REACT_APP_JNTUK_BASE_URL}/sms`,
            label: "School of Management Studies",
            newTab: true,
            isSubdomain: true,
          },
        ],
      },
    ],
  },
  {
    label: "Campus School's",
    children: [
      {
        to: "",
        label: "INSTITUTE OF SCIENCE & TECHNOLOGY",
        newTab: false,
        children: [
          {
            to: `${process.env.REACT_APP_JNTUK_BASE_URL}/shsr`,
            label: "School of Health Sciences and Research",
            newTab: true,
            isSubdomain: true,
          },
          {
            to: "https://jntukfoodtech.com",
            label: "School of Food Technology",
            newTab: true,
            isExternal: true,
          },
          {
            to: `${process.env.REACT_APP_JNTUK_BASE_URL}/sree`,
            label: "School of Renewable Energy & Environment",
            newTab: true,
            isSubdomain: true,
          },
          {
            to: "https://jntukbiotech.com",
            label: "School of Biotechnology",
            newTab: true,
            isExternal: true,
          },
          {
            to: `${process.env.REACT_APP_JNTUK_BASE_URL}/soa`,
            label: "School of Avionics",
            newTab: true,
            isSubdomain: true,
          },
          {
            to: `${process.env.REACT_APP_JNTUK_BASE_URL}/ssit`,
            label: "School of Spatial Information Technology",
            newTab: true,
            isSubdomain: true,
          },
          {
            to: `${process.env.REACT_APP_JNTUK_BASE_URL}/sopst`,
            label: "School of Pharmaceutical Sciences & Technologies",
            newTab: true,
            isSubdomain: true,
          },
          {
            to: `${process.env.REACT_APP_JNTUK_BASE_URL}/snt`,
            label: "School of Nano Technology",
            newTab: true,
            isSubdomain: true,
          },
        ],
      },
    ],
  },
  {
    label: "Examinations",
    children: [
      {
        to: "",
        label: "Examinations",
        newTab: false,
        children: [
          {
            to: "/profile/director-of-evaluation-de",
            label: "Director of Evaluation",
            newTab: false,
          },
          {
            to: "/controller-of-examination-ug",
            label: "Controller of Examinations (UG)",
            newTab: false,
          },
          {
            to: "/controller-of-examinations-pg-sdc",
            label: "Controller of Examinations (PG and SDC)",
            newTab: false,
          },
          {
            to: "/addl-controller-of-examinations",
            label: "Addl. Controller of Examinations",
            newTab: false,
          },
          {
            to: "/category/Academic-Calendars",
            label: "Academic Calendars",
            newTab: false,
          },
        ],
      },
      {
        to: "/university-examination-fee-structure-of-various-courses",
        label: "UNIVERSITY EXAMINATION FEE STRUCTURE",
        newTab: false,
      },
      {
        to: `${imageBaseURL}${JNTUK_PDF_Links.JNTUK_MALPRACTICE_GUIDELINES}`,
        label: "JNTUK MALPRACTICE GUIDELINES",
        newTab: true,
        pdfLink: true,
      },
      {
        to: `${imageBaseURL}${JNTUK_PDF_Links.PHD_AWARDEES_LIST_2016_2019}`,
        label: "PH.D AWARDEES LIST 2016-2019",
        newTab: true,
        pdfLink: true,
      },
      {
        to: "https://jntukresults.edu.in/",
        label: "RESULTS PORTAL",
        newTab: true,
        isSubDomain: true,
      },
      {
        to: "",
        label: "AFFILIATED COLLEGES",
        newTab: false,
        children: [
          {
            to: "/affiliated-colleges-east-godavari",
            label: "Affiliated Colleges – East Godavari",
            newTab: false,
          },
          {
            to: "/affiliated-colleges-west-godavari",
            label: "Affiliated Colleges – West Godavari",
            newTab: false,
          },
          {
            to: "/affiliated-colleges-krishna",
            label: "Affiliated Colleges – Krishna",
            newTab: false,
          },
          {
            to: "/affiliated-colleges-guntur",
            label: "Affiliated Colleges – Guntur",
            newTab: false,
          },
          {
            to: "/affiliated-colleges-prakasam",
            label: "Affiliated Colleges – Prakasam",
            newTab: false,
          },
        ],
      },
      {
        to: "/autonomous-colleges",
        label: "LIST OF AUTONOMOUS COLLEGES",
        newTab: false,
      },
      {
        to: "",
        label: "DOWNLOADS",
        newTab: false,
        children: [
          {
            to: "/category/Academic-Syllabus",
            label: "Syllabus",
            newTab: false,
          },
          {
            to: `${imageBaseURL}${JNTUK_PDF_Links["2F_of_UGC"]}`,
            label: "2F of UGC",
            newTab: true,
            pdfLink: true,
          },
          {
            to: `${imageBaseURL}${JNTUK_PDF_Links["12B_of_UGC"]}`,
            label: "12B of UGC",
            newTab: true,
            pdfLink: true,
          },
          {
            to: `${imageBaseURL}${JNTUK_PDF_Links.IT_Policies}`,
            label: "IT Policies",
            newTab: true,
            pdfLink: true,
          },
        ],
      },
    ],
  },
  {
    label: "Accreditations",
    children: [
      {
        to: "https://jntukiqac.com",
        label: "ABOUT NIRF",
        newTab: true,
        isExternal: true,
        children: [
          {
            to: "https://jntukiqac.com/meeting.php",
            label: "MINUTES & MEETING",
            newTab: true,
            isExternal: true,
          },
          {
            to: "https://jntukiqac.com/course.php",
            label: "COURSE-DETAILS",
            newTab: true,
            isExternal: true,
          },
          {
            to: "https://jntukiqac.com/naac_data.php",
            label: "NAAC DATA FILES",
            newTab: true,
            isExternal: true,
          },
          {
            to: "https://jntukiqac.com/programme.php",
            label: "PROGRAMME OFFERED",
            newTab: true,
            isExternal: true,
          },
        ],
      },
      {
        to: "",
        label: "FEEDBACK FORMS",
        children: [
          {
            to: "https://jntukiqac.com/feedback.php",
            label: "Alumini",
            newTab: true,
            isExternal: true,
          },
          {
            to: "https://jntukiqac.com/form_2.php",
            label: "Faculty",
            newTab: true,
            isExternal: true,
          },
          {
            to: "https://jntukiqac.com/form_3.php",
            label: "Industry",
            newTab: true,
            isExternal: true,
          },
          {
            to: "https://jntukiqac.com/form_4.php",
            label: "Student",
            newTab: true,
            isExternal: true,
          },
        ],
      },
      {
        to: "",
        label: "ACCREDITATIONS",
        children: [
          {
            to: "/naac-reports",
            label: "NAAC Reports",
            newTab: false,
          },
          {
            to: `${imageBaseURL}${JNTUK_PDF_Links.JNTUK_NAAC_SSR_2023}`,
            label: "JNTUK NAAC SSR 2023",
            newTab: true,
            pdfLink: true,
          },
          {
            to: `${imageBaseURL}${JNTUK_PDF_Links.NIRF_Overall_Report_2023}`,
            label: "NIRF Overall Report -2023",
            newTab: true,
            pdfLink: true,
          },
          {
            to: `${imageBaseURL}${JNTUK_PDF_Links.JNTUK_AQAR_2017_18}`,
            label: "JNTUK AQAR 2017-18",
            newTab: true,
            pdfLink: true,
          },
          {
            to: `${imageBaseURL}${JNTUK_PDF_Links.NIRF_Overall_Report_2022}`,
            label: "NIRF Overall Report -2022",
            newTab: true,
            pdfLink: true,
          },
          {
            to: `${imageBaseURL}${JNTUK_PDF_Links.NAAC_Self_Study_Report_Feb_2017}`,
            label: "NAAC Self Study Report (SSR) - Feb 2017",
            newTab: true,
            pdfLink: true,
          },
          {
            to: `${imageBaseURL}${JNTUK_PDF_Links.Annual_Quality_Assurance_Reports}`,
            label: "Annual Quality Assurance Reports(AQAR)",
            newTab: true,
            pdfLink: true,
          },
          {
            to: `${imageBaseURL}${JNTUK_PDF_Links.ARAQ_Of_Student_Satisfaction_Survey}`,
            label:
              " Analysis Report and Questionnaire of Student Satisfaction Survey",
            newTab: true,
            pdfLink: true,
          },
          {
            to: `${imageBaseURL}${JNTUK_PDF_Links.National_Institutional_Rankings_Framework_2020}`,
            label: "National Institutional Rankings Framework (NIRF) – 2020",
            newTab: true,
            pdfLink: true,
          },
        ],
      },
    ],
  },
  {
    label: "Information For...",
    children: [
      {
        to: "/campus-buildings",
        label: "Campus Building",
        newTab: false,
      },
      {
        to: "/convocations",
        label: "Convocations",
        newTab: false,
      },
      {
        to: "/category/Latest-Events",
        label: "Latest Events",
        newTab: false,
      },
      {
        to: "/jntuk-contacts",
        label: "Contacts",
        newTab: false,
      },
      {
        to: "https://infyspringboard.onwingspan.com/web/en/page/lex_auth_0136054520526520321",
        label: "Infosys Springboard",
        newTab: true,
        isExternal: true,
      },
      {
        to: "http://pc.jntuk.edu.in",
        label: "Placement Cell",
        newTab: true,
        isExternal: true,
      },
      {
        to: `${imageBaseURL}${JNTUK_PDF_Links.JNTUK_Newsletter}`,
        label: "JNTUK Newsletter",
        newTab: true,
        isExternal: true,
      },
      {
        to: "https://bcde.online",
        label: "Board for Community Development through Education (BCDE)",
        newTab: true,
        isExternal: true,
      },
      {
        to: "",
        label: "JNTUK UNITS",
        children: [
          {
            to: "/profile/sc-st-cell",
            label: "SC & ST Cell",
            newTab: false,
          },
          {
            to: "http://arc.jntuk.edu.in",
            label: "Anti Ragging Cell",
            newTab: true,
            isSubdomain: true,
          },
          {
            to: `${imageBaseURL}${JNTUK_PDF_Links.Sexual_Harassment_Committees}`,
            label: "Sexual Harassment Committees",
            newTab: true,
            pdfLink: true,
          },
          {
            to: "https://jntuknss.org",
            label: "NSS Cell",
            newTab: true,
            isExternal: true,
          },
          {
            to: "https://jntukiqac.com",
            label: "IQAC",
            newTab: true,
            isExternal: true,
          },
          {
            to: "https://ecosak.org.in",
            label: "Alumni Engagement",
            newTab: true,
            isExternal: true,
          },
          {
            to: "",
            label: "IIIP & SKILL DEVELOPMENT",
            children: [
              {
                to: `${imageBaseURL}${JNTUK_PDF_Links.Placements_Graph}`,
                label: "Placements Graph",
                newTab: true,
                pdfLink: true,
              },
              {
                to: `${imageBaseURL}${JNTUK_PDF_Links.Visits_to_Industry}`,
                label: "Visits to Industry's",
                newTab: true,
                pdfLink: true,
              },
              {
                to: `${imageBaseURL}${JNTUK_PDF_Links.Lab_Openings}`,
                label: "Lab Openings",
                newTab: true,
                pdfLink: true,
              },
              {
                to: `${imageBaseURL}${JNTUK_PDF_Links.Skill_Development_Programmes}`,
                label: "Skill Development Programmes",
                newTab: true,
                pdfLink: true,
              },
            ],
          },
        ],
      },
    ],
  },
];
function Accordion() {
  const renderNestedLevels = (data) => {
    return data.map((item, itemIndex) => (
      <SubLevelComp
        item={item}
        renderNestedLevels={renderNestedLevels}
        key={itemIndex}
      />
    ));
  };

  return renderNestedLevels(items);
}

const SubLevelComp = ({ item, renderNestedLevels }) => {
  const location = useLocation();
  const [selected, setselected] = React.useState("");

  const toggle = () => {
    setselected(selected === "" ? "active" : "");
  };

  const hasChidlren = (item) => {
    return Array.isArray(item.children) && item.children.length > 0;
  };

  const externalLink = (url) => {
    const userConfirmed = window.confirm(
      "You are being redirected to an external website. Please note that JNTUK Portal is not responsible for external websites content & privacy policies."
    );

    if (userConfirmed) {
      window.open(url, "_blank");
    } else {
    }
  };
  const subDomain = (url) => {
    const userConfirmed = window.confirm(
      "You are being redirected to an internal sub-domain website. The sub-domain website will open in a New Tab"
    );

    if (userConfirmed) {
      window.open(url, "_blank");
    } else {
    }
  };

  return (
    <li
      className={
        selected === "active"
          ? "menu-item-has-children current-menu-item"
          : "menu-item-has-children"
      }
    >
      <div className="d-flex">
        {item.newTab && item.isSubdomain ? (
          <a href="javascript: void(0)" onClick={() => subDomain(item.to)}>
            {item.label}
          </a>
        ) : item.newTab && item.isExternal ? (
          <a onClick={() => externalLink(item.to)}>{item.label}</a>
        ) : item.newTab && item.pdfLink ? (
          <a href={item.to} target="_blank">
            {item.label}
          </a>
        ) : (
          <Link
            to={item.to}
            className={location.pathname === item.to ? "active-menu" : ""}
          >
            {item.label}
          </Link>
        )}
        {hasChidlren(item) && <span onClick={() => toggle()}></span>}
      </div>
      {selected && (
        <ul
          className={
            selected === "active" ? "sub-menu current-menu" : "sub-menu"
          }
        >
          {hasChidlren(item) && renderNestedLevels(item.children)}
        </ul>
      )}
    </li>
  );
};

const RSMobileMenu = ({
  setMenuOpen,
  menuOpen,
  parentMenu,
  secondParentMenu,
  headerFullWidth,
}) => {
  const location = useLocation();

  return (
    <div
      className={
        headerFullWidth ? "container-fluid relative" : "container relative"
      }
    >
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            <Accordion />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;
