import React, { useState, useEffect } from "react";
import { AboutMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { BlocksRenderer } from "../../components/BlockRenderer";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import { useParams } from "react-router-dom";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";

const getChildPath = (type, setPath) => {
  let text = "Governance, Leadership And Management";
  if (!type) {
    setPath({
      pageCategory: "",
      pageName: "Governance, Leadership And Management",
    });
    return null;
  }
  if (type === "institutional_vision_and_leadership")
    text = "Institutional Vision and Leadership";
  else if (type === "core_values") text = "Core Values";
  else if (type === "institutional_values_and_best_practices")
    text = "Institutional Values and Best Practices";
  setPath({
    pageCategory: {
      label: "Governance, Leadership And Management",
      path: "jntukvision",
    },
    pageName: text,
  });
  return null;
};
const Content = () => {
  const [data, setData] = useState([]);
  const [path, setPath] = useState({
    pageCategory: "",
    pageName: "Governance, Leadership And Management",
  });
  const { type } = useParams();
  useEffect(() => {
    getChildPath(type, setPath);
    fetchData(JNTUK_API_Urls.JNTUK_Vision, setData);
  }, [type]);

  return (
    <React.Fragment>
      <SiteBreadcrumb
        pageCategory={path.pageCategory}
        pageName={path.pageName}
      />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 md-mb-30">
              <div className="about-intro">
                <AboutMenu />
                <SideBar />
              </div>
            </div>

            <div className="col-lg-6 pl-20 md-pl-14">
              <div className="content-wrapper">
                <div className="content-section">
                  <h3 style={{ marginBottom: "20px" }} title="Vision & Mission">
                    Vision & Mission
                  </h3>
                  {(!type ||
                    type === "institutional_vision_and_leadership") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes
                          ?.Institutional_Vision_and_Leadership || []
                      }
                    />
                  )}
                  {(!type || type === "core_values") && (
                    <BlocksRenderer
                      content={data?.data?.attributes?.Core_Values || []}
                    />
                  )}
                  {(!type ||
                    type === "institutional_values_and_best_practices") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes
                          ?.Institutional_Values_and_Best_Practices || []
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-3 pl-40 md-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default Content;
