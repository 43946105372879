import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useWindowSize = () => {
  const [internalMenuOpen, setInternalMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      const shouldShowMenu = window.innerWidth > 991;
      setInternalMenuOpen(shouldShowMenu);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [location.pathname]);

  return { internalMenuOpen, setInternalMenuOpen };
};

export default useWindowSize;