import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import ContactForm from "../../components/Contact/ContactForm";


const ContactMain = () => {
  return (
    <React.Fragment>
      {/* Contact Section Start */}
      <div className="rs-contact style2 md-pt-80 pb-110 md-pb-80">
        <div className="container">

          <div className="contact-widget">
            <SectionTitle
              sectionClass="sec-title text-center mb-50"
              titleClass="title black-color mb-14"
              title="College Registration"
              descClass="desc big"
              description={<></>}
            />

            {/* Contact Form */}
            <ContactForm submitBtnClass="btn-send" btnText="Register" />
            {/* Contact Form */}
          </div>
        </div>
      </div>
      {/* Contact Section End */}
    </React.Fragment>
  );
};

export default ContactMain;
