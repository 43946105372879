import React from "react";
import { BlocksRenderer } from "../../components/BlockRenderer";
import { imageBaseURL } from "../../utils/fetchData";

const AboutPart = ({ data }) => {
  return (
    <div className="content white-bg pt-30">
      <div className="course-overview">
        <div className="inner-box">
          <div className="row rs-team style1 orange-color transparent-bg">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="mb-20">
                {data?.data?.attributes?.Image?.data?.attributes?.url && (
                  <img
                    src={
                      imageBaseURL +
                        data?.data?.attributes?.Image?.data?.attributes?.url ||
                      ""
                    }
                    title={data?.data?.attributes?.Title}
                    alt={data?.data?.attributes?.Title}
                  />
                )}
                {data?.data?.attributes?.jntuk_directorate && (
                  <img className="test"
                    src={ imageBaseURL + data?.data?.attributes?.jntuk_directorate?.data?.attributes?.Image?.data?.attributes?.url || ""}
                    title={
                      data?.data?.attributes?.Name ||
                      data?.data?.attributes?.jntuk_directorate?.data
                        ?.attributes.Name
                    }
                    alt={
                      data?.data?.attributes?.Name ||
                      data?.data?.attributes?.jntuk_directorate?.data
                        ?.attributes.Name
                    }
                  />
                )}
                {data?.data?.attributes?.sc_and_st_cell_user && (
                  <img
                    src={
                      imageBaseURL +
                        data?.data?.attributes?.sc_and_st_cell_user?.data
                          ?.attributes?.Image?.data?.attributes?.url || ""
                    }
                    title={
                      data?.data?.attributes?.sc_and_st_cell_user?.data
                        ?.attributes.Name
                    }
                    alt={
                      data?.data?.attributes?.sc_and_st_cell_user?.data
                        ?.attributes.Name
                    }
                  />
                )}
              </div>
              <h3 className="pName" title={data?.data?.attributes?.Title}>
                {data?.data?.attributes?.Title}
              </h3>
              <h3
                className="pName"
                title={
                  data?.data?.attributes?.Name ||
                  data?.data?.attributes?.jntuk_directorate?.data?.attributes
                    .Name
                }
              >
                {data?.data?.attributes?.Name ||
                  data?.data?.attributes?.jntuk_directorate?.data?.attributes
                    .Name}
              </h3>
              {data?.data?.attributes?.sc_and_st_cell_user && (
                <h3
                  className="pName"
                  title={
                    data?.data?.attributes?.sc_and_st_cell_user?.data
                      ?.attributes.Name
                  }
                >
                  {
                    data?.data?.attributes?.sc_and_st_cell_user?.data
                      ?.attributes.Name
                  }
                </h3>
              )}

              <div style={{ color: "black" }}>
                <div className="education">
                  {data?.data?.attributes?.Education && (
                    <BlocksRenderer
                      content={data?.data?.attributes?.Education || []}
                    />
                  )}
                  {data?.data?.attributes?.jntuk_directorate && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes?.jntuk_directorate?.data
                          ?.attributes?.Education || []
                      }
                    />
                  )}
                  {data?.data?.attributes?.sc_and_st_cell_user && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes?.sc_and_st_cell_user?.data
                          ?.attributes?.Education || []
                      }
                    />
                  )}
                </div>
                <div className="subTitle">
                  {data?.data?.attributes?.Sub_Title && (
                    <BlocksRenderer
                      content={data?.data?.attributes?.Sub_Title || []}
                    />
                  )}
                </div>
              </div>
              <div style={{ color: "black" }}>
                <div className="subTitle">
                  {data?.data?.attributes?.jntuk_directorate && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes?.jntuk_directorate?.data
                          ?.attributes?.Designation || []
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {data?.data?.attributes?.Body && (
            <BlocksRenderer content={data?.data?.attributes?.Body || []} />
          )}
          {data?.data?.attributes?.jntuk_directorate && (
            <BlocksRenderer
              content={
                data?.data?.attributes?.jntuk_directorate?.data?.attributes
                  ?.Description || []
              }
            />
          )}
          {data?.data?.attributes?.sc_and_st_cell_user && (
            <BlocksRenderer
              content={
                data?.data?.attributes?.sc_and_st_cell_user?.data?.attributes
                  ?.Description || []
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutPart;
