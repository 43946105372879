import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SearchModal from "../../components/Layout/Header/SearchModal";
import footerLogo from "../../assets/img/logo/lite-logo.png";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData, imageBaseURL } from "../../utils/fetchData";
import Accreditation from "../../components/Common/Accreditation";
import { AdministrationMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import profIcon from "../../assets/img/profile-icon.png";
import RightSidebar from "../../components/Common/RightSidebar";
import Pagination from "../../components/Pagination";
import { useFontSize } from "../../FontSizeContext";
import { formatDate } from "../../utils/methods";

const JNTUKViceChancellor = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const totalItems = data?.meta?.pagination?.total;
  const { fontSize } = useFontSize();
  useEffect(() => {
    fetchData(
      JNTUK_API_Urls.Former_Vice_Chancellors +
        `&pagination[page]=${currentPage}`,
      setData
    );
  }, [currentPage]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <React.Fragment>
      <Helmet>
        <title>JNTUK Vice Chancellor's | JNTU Kakinada</title>
        <meta
          name="description"
          content="JNTUK Vice Chancellor's Jawaharlal Nehru Technological University Kakinada"
        />
        <meta
          name="keywords"
          content="JNTUK Vice Chancellor's Jawaharlal Nehru Technological University Kakinada"
        ></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <style>
        {`
          body,
          .rs-latest-events.style1 .event-wrap .events-short .date-part .date,
          .widget ul li a {
              font-size: ${fontSize}px;
          }
          .rs-inner-blog .blog-item .blog-content .blog-title,
          .widget h1,.content-wrapper h2, .heading, .college-title{
              font-size: calc(${fontSize}px + 10px);
          }
          .breadcrumb-bg .breadcrumbs-text ul li{
            font-size: calc(${fontSize}px + 2px);
          }
          
          .full-width-header .rs-header .topbar-area .topbar-right li.login-register a,
          .readon.orange-btn, .rs-latest-events.bg-wrap .notification-filter button,
          .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .slNo, 
          .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > a,
          .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > ul li > a,
          table th, table td {                       
              font-size: calc(${fontSize}px - 2px);
          }

          .rs-footer .footer-top-social .social_msg{
              font-size: calc(${fontSize}px + 18px);
          }
          .rs-footer .footer-center h3{
              font-size: calc(${fontSize}px + 12px);
          }
          .rs-footer.home9-style .footer-top .widget-title,
          .rs-team.style1 .about-btn--primary,
          .rs-team.style1 .about-btn--secondary,
          .course-overview .inner-box h5{
              font-size: calc(${fontSize}px + 4px);
          }
          .rs-footer .footer-center h4,
          .rightBar h4{
              font-size: calc(${fontSize}px + 6px);
          }
          
      `}
      </style>
      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle="JNTUK Vice Chancellor's"
          pageName="JNTUK Vice Chancellor's"
          breadcrumbsClass="breadcrumb-bg"
        />
        {/* breadcrumb-area-start */}

        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 md-mb-30">
                <div className="about-intro">
                  <AdministrationMenu />
                  <SideBar />
                </div>
              </div>
              <div className="col-lg-6 pl-20 md-pl-14">
                <div className="row">
                  <div className="rs-inner-blog col-lg-12">
                    <h3 className="college-title" title="JNTUK Vice Chancellor's">JNTUK Vice Chancellor's</h3>
                    <div className="overflow-x">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Name</th>
                            <th scope="col">Duration</th>
                            <th scope="col" align="center">
                              Photograph
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.map((obj, index) => (
                            <tr>
                              <td>
                                {obj?.attributes?.SrNo?.toString().padStart(
                                  2,
                                  "0"
                                )}
                                .
                              </td>
                              <td>{obj?.attributes?.Name}</td>
                              <td>
                                {formatDate(obj?.attributes?.From_Date)}
                                {" to "}
                                {formatDate(obj?.attributes?.To_Date) ||
                                  "--"}
                              </td>
                              <td align="center">
                                <img
                                  title="JNTUK Vice Chancellor's"
                                  alt="JNTUK Vice Chancellor's"
                                  src={
                                    obj?.attributes?.Image.data
                                      ? imageBaseURL +
                                        obj?.attributes?.Image.data.attributes
                                          .url
                                      : profIcon
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      paginate={paginate}
                      arrayLength={totalItems / itemsPerPage}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 pl-40 md-pl-14">
                <RightSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accreditation />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default JNTUKViceChancellor;
