import React from "react";
import { Link } from "react-router-dom";
import { JNTUK_PDF_Links } from "../../../utils/api.config";
import { imageBaseURL } from "../../../utils/fetchData";
import naaclogo from "../../../assets/img/logo/naac-logo.png";

import menuImg1 from "../../../assets/img/menu/1.png";
import menuImg2 from "../../../assets/img/menu/2.png";
import menuImg3 from "../../../assets/img/menu/3.png";
import menuImg5 from "../../../assets/img/menu/5.png";
import menuImg6 from "../../../assets/img/menu/6.png";

const MenuItems = (props) => {
  const externalLink = (url) => {
    const userConfirmed = window.confirm(
      "You are being redirected to an external website. Please note that JNTUK Portal is not responsible for external websites content & privacy policies."
    );

    if (userConfirmed) {
      window.open(url, "_blank");
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <li className="rs-mega-menu menu-item-has-children submenuLeftAligned">
        <Link to="#">About</Link>
        <ul className="mega-menu">
          <li className="mega-menu-container">
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/about">About JNTUK</Link>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <a href="/jntukvision">
                    Governance, Leadership and Management
                  </a>
                  <ul>
                    <li>
                      <a href="/jntukvision/institutional_vision_and_leadership">
                        Institutional Vision and Leadership
                      </a>
                    </li>
                    <li>
                      <a href="/jntukvision/core_values">Core Values</a>
                    </li>
                    <li>
                      <a href="/jntukvision/institutional_values_and_best_practices">
                        Institutional Values and Best Practices
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/SWOC">SWOC</Link>
                  <ul>
                    <li>
                      <Link to="/SWOC/institutional_strength">
                        Institutional Strength
                      </Link>
                    </li>
                    <li>
                      <Link to="/SWOC/institutional_weakness">
                        Institutional Weakness
                      </Link>
                    </li>
                    <li>
                      <Link to="/SWOC/institutional_opportunity">
                        Institutional Opportunity
                      </Link>
                    </li>
                    <li>
                      <Link to="/SWOC/institutional_challenge">
                        Institutional Challenge
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/SDD">Strategy Development and Deployment</Link>
                </li>
                <li>
                  <Link to="/institutional-bodies">Institutional Bodies</Link>
                </li>
                <li>
                  <a
                    href={`${imageBaseURL}${JNTUK_PDF_Links.JNTU_Act_Link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    JNTU Act
                  </a>
                </li>
                <li>
                  <a
                    href={`${imageBaseURL}${JNTUK_PDF_Links.RTI_Act_Link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    RTI Act
                  </a>
                </li>
                <li>
                  <a
                    href={`https://docs.google.com/gview?url=${imageBaseURL}${JNTUK_PDF_Links.Anti_Ragging_Link}&embedded=true`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Anti Ragging
                  </a>
                </li>
                <li>
                  <Link to="/university-song">University Song</Link>
                </li>
                <li>
                  <Link to="/category/Press-Releases">Media/Press</Link>
                </li>
                <li>
                  <Link to="/jntuk-contacts">Contacts</Link>
                </li>
                <li>
                  <Link to="/gallery/univ-gallery">Univ Gallery</Link>
                </li>
                <li>
                  <Link to="/convocations">Convocations</Link>
                </li>
                <li>
                  <Link to="/category/Tie-ups-and-Mou’s">Tie Ups & MOU's</Link>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/governing-hierarchy">Governing Heirarchy</Link>
                </li>
                <li>
                  <Link to="/location-map">Location Map</Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
      <li className="rs-mega-menu menu-item-has-children">
        <Link to="#">Administration</Link>
        <ul className="mega-menu">
          <li className="mega-menu-container">
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/profile/chancellor">Chancellor</Link>
                </li>
                <li>
                  <Link to="/profile/vice-chancellor">Vice Chancellor</Link>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/profile/rector">Rector</Link>
                </li>
                <li>
                  <Link to="/profile/registrar">Registrar</Link>
                </li>
                <li>
                  <Link to="/profile/osd">OSD</Link>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/executive-council">Executive Council</Link>
                </li>
                <li>
                  <Link to="/academic-senate">Academic Senate</Link>
                </li>
                <li>
                  <Link to="/board-of-studies">Board of Studies</Link>
                </li>
                <li>
                  <Link to="/jntuk-vice-chancellor">
                    JNTUK Vice Chancellor's
                  </Link>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/directorates">Directorates</Link>
                  <ul>
                    <li>
                      <Link to="/profile/academics-and-planning-dap">
                        Director, Academic Planning
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-academic-audit">
                        Director, Academic Audit
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-of-evaluation-de">
                        Director of Evaluation
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/admissions-da">
                        Director, Admissions
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-research-and-development">
                        Director, Research and Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-libraryn">
                        Director, ODL & OL Programmes
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/faculty-development-centre">
                        Director, Faculty Development Centre
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/infrastructure-development">
                        Chief Engineer & Director, Infrastructure
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-foreign-university-relations-dfur">
                        Director, Foreign University Relations
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/institute-of-science-and-technology-ist">
                        Director, IST
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/internal-quality-assurance-cell">
                        Director, IQAC
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/school-of-management-studies">
                        Director - School of Management Studies
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-industry-institute-interaction-placements-and-training">
                        Director, IIIP & Training
                      </Link>
                    </li>

                    {/* <li>
                      <Link to="/profile/director-green-campus-initiatives">
                        Director, Green Campus Initiatives
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/profile/director-empowerment-of-women-grievances">
                        Director, Empowerment of Women & Grievances
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/profile/director-bics">Director, BICS</Link>
                    </li> */}
                    <li>
                      <Link to="/profile/director-legal-and-govt-affairs">
                        Director Legal & Government Affairs
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/profile/director-university-games-and-sports">
                        Director(I/C) Director, University Games & Sports
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/director-incubation">
                        Director(I/C) Incubation
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/profile/director-it-infrastructure">
                        Director, IT Infrastructure
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/research-innovation-centre">
                        Director, Research Innovation Centre
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link style={{ cursor: "default" }} to="#">
                    Principal's
                  </Link>
                  <ul>
                    <li>
                      <Link to="/profile/principal-university-college-of-engineering-kakinada">
                        Principal, UCEK
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile/principal-university-college-of-engineering-narasaraopeta">
                        Principal, UCEN
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/special-officers">Special Officers</Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
      <li className="rs-mega-menu menu-item-has-children">
        <Link to="#" as="#">
          Academics
        </Link>
        <ul className="mega-menu">
          <li className="mega-menu-container">
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/methodology">Methodology</Link>
                </li>
                <li>
                  <Link to="/admissions">Admission</Link>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/under-graduate">
                    UG - Programmes Offered - Affiliated
                  </Link>
                  <ul>
                    <li>
                      <a href="/course/1">Bachelor of Technology</a>
                    </li>
                    <li>
                      <a href="/course/2">Bachelor of Pharmacy</a>
                    </li>
                    <li>
                      <a href="/course/3">Bachelor of Architecture</a>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/post-graduate">
                    PG - Programmers Offered - Affiliated
                  </Link>
                  <ul>
                    <li>
                      <a href="/course/4">Pharm D</a>
                    </li>
                    <li>
                      <a href="/course/5">Master of Technology</a>
                    </li>
                    <li>
                      <a href="/course/6">Master of Pharmacy</a>
                    </li>
                    <li>
                      <a href="/course/7">Master of Computer Applications</a>
                    </li>
                    <li>
                      <a href="/course/8">Master of Bussiness Administration</a>
                    </li>
                    <li>
                      <a href="/course/9">Management of Applied Management</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/phd-courses">Research</Link>
                  <ul>
                    <li>
                      <a href="/phd-courses/Promotion_of_Research_and_Facilities">
                        Promotion of Research and Facilities
                      </a>
                    </li>
                    <li>
                      <a href="/phd-courses/Innovations_and_Extension">
                        Innovations and Extension
                      </a>
                    </li>
                    <li>
                      <a href="/phd-courses/Innovation_Ecosystem">
                        Innovation Ecosystem
                      </a>
                    </li>
                    <li>
                      <a href="/phd-courses/Research_Branches">Branches</a>
                    </li>
                    <li>
                      <a href="/phd-courses/Regulations_and_Guidelines">
                        Regulations & Guidelines
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/category/Academic-Calendars">
                    Academic Calendars
                  </Link>
                </li>
                <li>
                  <Link to="/programmes-offered">Programmes Offered</Link>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/academicsdata">Circular Aspects</Link>
                  <ul>
                    <li>
                      <a href="/academicsdata/Circular_Aspects">
                        Circular Aspects
                      </a>
                    </li>
                    <li>
                      <a href="/academicsdata/Curriculum_Design_and_Development">
                        Curriculum Design and Development
                      </a>
                    </li>
                    <li>
                      <a href="/academicsdata/Curriculum_Enrichment">
                        Curriculum Enrichment
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="/teachingLearnProcess">
                    Teaching-Learning Process
                  </Link>
                </li>
                <li>
                  <Link to="/studentsupportprogression">
                    Student Support and Progression
                  </Link>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/infrastructurelearning">
                    Infrastructure and Learning Resources
                  </Link>
                  <ul>
                    <li>
                      <a href="/infrastructurelearning/physical_facilities">
                        Physical Facilities
                      </a>
                    </li>
                    <li>
                      <a href="/infrastructurelearning/adequate_facilities">
                        Adequate Facilities
                      </a>
                    </li>
                    <li>
                      <a href="/infrastructurelearning/learning_resource">
                        Learning Resource
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="#">Constituent Colleges/Units</Link>
                  <ul>
                    <li>
                      <a
                        href={"https://www.jntucek.ac.in"}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          externalLink("https://www.jntucek.ac.in");
                        }}
                        onContextMenu={(e) => {
                          return null;
                        }}
                      >
                        University College of Engineering Kakinada Campus
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://jntukucen.ac.in"}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          externalLink("https://jntukucen.ac.in");
                        }}
                        onContextMenu={(e) => {
                          return null;
                        }}
                      >
                        University College of Engineering Narasaraopet Campus
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_JNTUK_BASE_URL}/sms`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        School of Management Studies
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
      <li className="rs-mega-menu menu-item-has-children">
        <Link to="#">Campus School's</Link>
        <ul className="mega-menu">
          <li className="mega-menu-container">
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link style={{ cursor: "default" }} to="#">
                    INSTITUTE OF SCIENCE & TECHNOLOGY
                  </Link>
                  <ul>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_JNTUK_BASE_URL}/shsr`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        School of Health Sciences and Research
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://jntukfoodtech.com"}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          externalLink("https://jntukfoodtech.com");
                        }}
                        onContextMenu={(e) => {
                          return null;
                        }}
                      >
                        School of Food Technology
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_JNTUK_BASE_URL}/sree`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        School of Renewable Energy & Environment
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://jntukbiotech.com"}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          externalLink("https://jntukbiotech.com");
                        }}
                        onContextMenu={(e) => {
                          return null;
                        }}
                      >
                        School of Biotechnology
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_JNTUK_BASE_URL}/soa`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        School of Avionics
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_JNTUK_BASE_URL}/ssit`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        School of Spatial Information Technology
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_JNTUK_BASE_URL}/sopst`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        School of Pharmaceutical Sciences & Technologies
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_JNTUK_BASE_URL}/snt`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        School of Nano Technology
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="single-megamenu col">
              <div className="menuImg">
                <div>
                  <a
                    href={`${process.env.REACT_APP_JNTUK_BASE_URL}/shsr`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={menuImg1}
                      title="School of Health Sciences And Research"
                      alt="School of Health Sciences And Research"
                    />
                    <p>School of Health Sciences And Research</p>
                  </a>
                </div>
                <div>
                  <a
                    href={"https://jntukfoodtech.com"}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      externalLink("https://jntukfoodtech.com");
                    }}
                    onContextMenu={(e) => {
                      return null;
                    }}
                  >
                    <img
                      src={menuImg2}
                      title="School Of Food Technology"
                      alt="School Of Food Technology"
                    />
                    <p>School Of Food Technology</p>
                  </a>
                </div>
                <div>
                  <a
                    href={`${process.env.REACT_APP_JNTUK_BASE_URL}/sree`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={menuImg3}
                      title="School Of Renewable Energy & Environment"
                      alt="School Of Renewable Energy & Environment"
                    />
                    <p>School Of Renewable Energy & Environment</p>
                  </a>
                </div>
                <div>
                  <a
                    href={"https://jntukbiotech.com"}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      externalLink("https://jntukbiotech.com");
                    }}
                    onContextMenu={(e) => {
                      return null;
                    }}
                  >
                    <img
                      src={menuImg2}
                      title="School Of Biotechnology"
                      alt="School Of Biotechnology"
                    />
                    <p>School Of Biotechnology</p>
                  </a>
                </div>
                <div>
                  <a
                    href={`${process.env.REACT_APP_JNTUK_BASE_URL}/soa`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={menuImg5}
                      title="School Of Avionics"
                      alt="School Of Avionics"
                    />
                    <p>School Of Avionics</p>
                  </a>
                </div>
                <div>
                  <a
                    href={`${process.env.REACT_APP_JNTUK_BASE_URL}/ssit`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={menuImg6}
                      title="School Of Spatial Information Technolgy"
                      alt="School Of Spatial Information Technolgy"
                    />
                    <p>School Of Spatial Information Technolgy</p>
                  </a>
                </div>
                <div>
                  <a
                    href={`${process.env.REACT_APP_JNTUK_BASE_URL}/sopst`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={menuImg2}
                      title="School Of Pharmaceutical Sciences & Technologies"
                      alt="School Of Pharmaceutical Sciences & Technologies"
                    />
                    <p>School Of Pharmaceutical Sciences & Technologies</p>
                  </a>
                </div>
                <div>
                  <a
                    href={`${process.env.REACT_APP_JNTUK_BASE_URL}/snt`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={menuImg1}
                      title="School Of Nano Technolgy"
                      alt="School Of Nano Technolgy"
                    />
                    <p>School Of Nano Technolgy</p>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
      <li className="rs-mega-menu menu-item-has-children">
        <Link to="#" as="#">
          Examinations
        </Link>
        <ul className="mega-menu">
          <li className="mega-menu-container">
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="#">EXAMINATIONS</Link>
                  <ul>
                    <li>
                      <Link to="/profile/director-of-evaluation-de">
                        Director of Evaluation
                      </Link>
                    </li>
                    <li>
                      <Link to="/controller-of-examination-ug">
                        Controller of Examinations (UG)
                      </Link>
                    </li>
                    <li>
                      <Link to="/controller-of-examinations-pg-sdc">
                        Controller of Examinations (PG and SDC)
                      </Link>
                    </li>
                    <li>
                      <Link to="/addl-controller-of-examinations">
                        Addl. Controller of Examinations
                      </Link>
                    </li>
                    <li>
                      <Link to="/category/Academic-Calendars">
                        Academic Calendars
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/university-examination-fee-structure-of-various-courses">
                    UNIVERSITY EXAMINATION FEE STRUCTURE
                  </Link>
                </li>
                <li>
                  <a
                    href={`${imageBaseURL}${JNTUK_PDF_Links.JNTUK_MALPRACTICE_GUIDELINES}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    JNTUK MALPRACTICE GUIDELINES
                  </a>
                </li>
              </ul>
            </div>
            <div className="single-megamenu ">
              <ul className="sub-menu">
                <li>
                  <a
                    href={`${imageBaseURL}${JNTUK_PDF_Links.PHD_AWARDEES_LIST_2016_2019}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    PH.D AWARDEES LIST 2016-2019
                  </a>
                </li>
                <li>
                  <a
                    href="https://jntukresults.edu.in/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    RESULTS PORTAL
                  </a>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="#">AFFILIATED COLLEGES</Link>
                  <ul>
                    <li>
                      <Link to="/affiliated-colleges-east-godavari">
                        Affiliated Colleges – East Godavari
                      </Link>
                    </li>
                    <li>
                      <Link to="/affiliated-colleges-west-godavari">
                        Affiliated Colleges – West Godavari
                      </Link>
                    </li>
                    <li>
                      <Link to="/affiliated-colleges-krishna">
                        Affiliated Colleges – Krishna
                      </Link>
                    </li>
                    <li>
                      <Link to="/affiliated-colleges-guntur">
                        {" "}
                        Affiliated Colleges – Guntur
                      </Link>
                    </li>
                    <li>
                      <Link to="/affiliated-colleges-prakasam">
                        {" "}
                        Affiliated Colleges – Prakasam
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="/autonomous-colleges">
                    LIST OF AUTONOMOUS COLLEGES
                  </Link>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="#">DOWNLOADS</Link>
                  <ul>
                    <li>
                      <Link to="/category/Academic-Syllabus">Syllabus</Link>
                    </li>
                    <li>
                      <a
                        href={`${imageBaseURL}${JNTUK_PDF_Links["2F_of_UGC"]}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        2F of UGC
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${imageBaseURL}${JNTUK_PDF_Links["12B_of_UGC"]}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        12B of UGC
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${imageBaseURL}${JNTUK_PDF_Links.IT_Policies}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        IT Policies
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
      <li className="rs-mega-menu menu-item-has-children submenuRightAligned">
        <Link to="#">Accreditations</Link>
        <ul className="mega-menu">
          <li className="mega-menu-container">
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <a
                    href={"https://jntukiqac.com"}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      externalLink("https://jntukiqac.com");
                    }}
                    onContextMenu={(e) => {
                      return null;
                    }}
                  >
                    ABOUT NIRF
                  </a>
                  <ul>
                    <li>
                      <a
                        href={"https://jntukiqac.com/meeting.php"}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          externalLink("https://jntukiqac.com/meeting.php");
                        }}
                        onContextMenu={(e) => {
                          return null;
                        }}
                      >
                        MINUTES & MEETING
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://jntukiqac.com/course.php"}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          externalLink("https://jntukiqac.com/course.php");
                        }}
                        onContextMenu={(e) => {
                          return null;
                        }}
                      >
                        COURSE-DETAILS
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://jntukiqac.com/naac_data.php"}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          externalLink("https://jntukiqac.com/naac_data.php");
                        }}
                        onContextMenu={(e) => {
                          return null;
                        }}
                      >
                        NAAC DATA FILES
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://jntukiqac.com/programme.php"}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          externalLink("https://jntukiqac.com/programme.php");
                        }}
                        onContextMenu={(e) => {
                          return null;
                        }}
                      >
                        PROGRAMME OFFERED
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="sub-menu">
                <li>
                  <Link to="#">FEEDBACK FORMS</Link>
                  <ul>
                    <li>
                      <a
                        href={"https://jntukiqac.com/feedback.php"}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          externalLink("https://jntukiqac.com/feedback.php");
                        }}
                        onContextMenu={(e) => {
                          return null;
                        }}
                      >
                        Alumini
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://jntukiqac.com/form_2.php"}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          externalLink("https://jntukiqac.com/form_2.php");
                        }}
                        onContextMenu={(e) => {
                          return null;
                        }}
                      >
                        Faculty
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://jntukiqac.com/form_3.php"}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          externalLink("https://jntukiqac.com/form_3.php");
                        }}
                        onContextMenu={(e) => {
                          return null;
                        }}
                      >
                        Industry
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://jntukiqac.com/form_4.php"}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          externalLink("https://jntukiqac.com/form_4.php");
                        }}
                        onContextMenu={(e) => {
                          return null;
                        }}
                      >
                        Student
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="single-megamenu">
              <ul className="sub-menu">
                <li>
                  <Link to="#">ACCREDITATIONS</Link>
                  <ul>
                    <li>
                      <Link to="/naac-reports">NAAC Reports</Link>
                    </li>
                    <li>
                      <a
                        href={`${imageBaseURL}${JNTUK_PDF_Links.JNTUK_NAAC_SSR_2023}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        JNTUK NAAC SSR 2023
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${imageBaseURL}${JNTUK_PDF_Links.NIRF_Overall_Report_2023}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        NIRF Overall Report -2023
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${imageBaseURL}${JNTUK_PDF_Links.JNTUK_AQAR_2017_18}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        JNTUK AQAR 2017-18
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${imageBaseURL}${JNTUK_PDF_Links.NIRF_Overall_Report_2022}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        NIRF Overall Report -2022
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${imageBaseURL}${JNTUK_PDF_Links.NAAC_Self_Study_Report_Feb_2017}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        NAAC Self Study Report (SSR) - Feb 2017
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${imageBaseURL}${JNTUK_PDF_Links.Annual_Quality_Assurance_Reports}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Annual Quality Assurance Reports(AQAR)
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${imageBaseURL}${JNTUK_PDF_Links.ARAQ_Of_Student_Satisfaction_Survey}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Analysis Report and Questionnaire of Student
                        Satisfaction Survey
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${imageBaseURL}${JNTUK_PDF_Links.National_Institutional_Rankings_Framework_2020}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        National Institutional Rankings Framework (NIRF) – 2020
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="single-megamenu align-self-end">
              <img src={naaclogo} />
            </div>
          </li>
        </ul>
      </li>
    </React.Fragment>
  );
};

export default MenuItems;
