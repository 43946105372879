import React, { useState, useEffect } from "react";
import { ExaminationMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { BlocksRenderer } from "../../components/BlockRenderer";
import { fetchData, imageBaseURL } from "../../utils/fetchData";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";
import Pagination from "../../components/Pagination";
import { JNTUK_API_Urls } from "../../utils/api.config";

const SDDContent = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const totalItems = data?.meta?.pagination?.total;
  useEffect(() => {
    fetchData(
      JNTUK_API_Urls.Addl_Controller_of_Examinations +
        `&pagination[page]=${currentPage}`,
      setData
    );
  }, [currentPage]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <React.Fragment>
      <SiteBreadcrumb pageName="Addl Controller of Examinations" />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 md-mb-30">
              <div className="about-intro">
                <ExaminationMenu />
                <SideBar />
              </div>
            </div>
            <div className="col-lg-6 pl-20 md-pl-14">
              <div className="content-wrapper">
                <h3 className="heading" title="Addl Controller of Examinations">Addl Controller of Examinations</h3>
                <div className="content-section pt-30">
                  {data?.data?.map((obj) => (
                    <div className="additionalControllerExamination">
                      {obj?.attributes?.Image?.data?.attributes?.formats
                        ?.thumbnail?.url ? (
                        <img
                          src={
                            imageBaseURL +
                              obj?.attributes?.Image?.data?.attributes?.formats
                                ?.thumbnail?.url || ""
                          }
                          alt={"Addl Controller of Examinations"}
                          title={"Addl Controller of Examinations"}
                        />
                      ) : (
                        <img
                          src={
                            imageBaseURL +
                              obj?.attributes?.Image?.data?.attributes?.url ||
                            ""
                          }
                          alt={"Addl Controller of Examinations"}
                          title={"Addl Controller of Examinations"}
                        />
                      )}

                      <div className="details">
                        <BlocksRenderer content={obj?.attributes?.Body || []} />
                      </div>
                    </div>
                  ))}
                  {/* Pagination */}
                  <Pagination
                    currentPage={currentPage}
                    paginate={paginate}
                    arrayLength={totalItems / itemsPerPage}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 pl-40 md-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default SDDContent;
