import React from "react";
import { Link } from "react-router-dom";
import teamImg2 from "../../../assets/img/about/nehru02.jpg";
import { imageBaseURL } from "../../../utils/fetchData";
import { JNTUK_PDF_Links } from "../../../utils/api.config";

function SideBar(props) {
  return (
    <div className="row rs-team style1 orange-color transparent-bg clearfix d-none d-lg-block">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="about-top-border"></div>
        <div className="">
          <img
            src={teamImg2}
            alt="Jawaharlal Nehru"
            title="Jawaharlal Nehru"
            className="w-100"
          />
        </div>
        <Link to="/profile/vice-chancellor" className="about-btn--primary">
          <span>Vice Chancellor</span>
          <span>
            <i className="far fa-angle-right" aria-hidden="true"></i>
            <i className="far fa-angle-right" aria-hidden="true"></i>
            <i className="far fa-angle-right" aria-hidden="true"></i>
          </span>
        </Link>
        <a
          href={`${imageBaseURL}${JNTUK_PDF_Links.Directory_Pdf}`}
          target="_blank"
          rel="noopener noreferrer"
          className="about-btn--secondary"
        >
          <span>JNTUK Directory</span>
          <span>
            <i className="far fa-angle-right" aria-hidden="true"></i>
            <i className="far fa-angle-right" aria-hidden="true"></i>
            <i className="far fa-angle-right" aria-hidden="true"></i>
          </span>
        </a>
        <Link
          to="/category/Notifications-and-Circulars"
          className="about-btn--secondary"
        >
          <span>Notification Center</span>
          <span>
            <i className="far fa-angle-right" aria-hidden="true"></i>
            <i className="far fa-angle-right" aria-hidden="true"></i>
            <i className="far fa-angle-right" aria-hidden="true"></i>
          </span>
        </Link>
      </div>
    </div>
  );
}

export default SideBar;
