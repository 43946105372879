import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";

// Categories Icon
import icon1 from "../../assets/img/sites/results.png";
import icon2 from "../../assets/img/sites/digital-monitoring.png";
import odl_ol from "../../assets/img/sites/odl.png";
import icon4 from "../../assets/img/sites/alumni.png";
import icon5 from "../../assets/img/sites/directorate-academic-unit.png";
import icon6 from "../../assets/img/sites/research-development.png";
import icon7 from "../../assets/img/sites/nss.png";
import icon8 from "../../assets/img/sites/anti-ragging-cell.png";
import icon9 from "../../assets/img/sites/iqac.png";
import lms from "../../assets/img/sites/lms.png";

const Categories = () => {
  const externalLink = (url) => {
    const userConfirmed = window.confirm(
      "You are being redirected to an external website. Please note that JNTUK Portal is not responsible for external websites content & privacy policies."
    );

    if (userConfirmed) {
      window.open(url, "_blank");
    } else {
    }
  };

  return (
    <div className="rs-subject style1 pt-50 pb-30 md-pt-64 md-pb-40">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-30 text-center md-mb-30"
          subtitleClass=""
          subtitle=""
          titleClass="sub-title mb-0 orange text-start"
          title="JNTUK Sites"
        />
        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5">
          <div className="col mb-30">
            <div
              className="site-wrap lightBlue-bg text-center"
              onClick={() => externalLink("https://lms.jntuk.org/")}
            >
              <img
                src={lms}
                alt="Learning Management System"
                title="Learning Management System"
              />
              <div className="content-part">
                <h4 className="title" title="Learning Management System">
                  LMS
                </h4>
              </div>
            </div>
          </div>
          <div className="col mb-30">
            <div
              className="site-wrap lightBlue-bg text-center"
              onClick={() => externalLink("https://jntukdaaportal.org")}
            >
              <img src={icon5} alt="DAA" title="DAA" />
              <div className="content-part">
                <h4 className="title" title="DAA">
                  DAA
                </h4>
              </div>
            </div>
          </div>
          <div className="col mb-30">
            <div
              className="site-wrap lightBlue-bg text-center"
              onClick={() => externalLink("http://jntukresults.edu.in")}
            >
              <img src={icon1} alt="Results Portal" title="Results Portal" />
              <div className="content-part">
                <h4 className="title" title="Results Portal">
                  Examinations & Results
                </h4>
              </div>
            </div>
          </div>
          <div className="col mb-30">
            <div
              className="site-wrap lightBlue-bg text-center"
              onClick={() => externalLink("https://jntukrd.in")}
            >
              <img src={icon6} alt="R&D" title="R&D" />
              <div className="content-part">
                <h4 className="title" title="R&D">
                  R&D
                </h4>
              </div>
            </div>
          </div>
          <div className="col mb-30">
            <div
              className="site-wrap lightBlue-bg text-center"
              onClick={() => externalLink("https://odl.jntuk.org/")}
            >
              <img src={odl_ol} alt="ODL & OL" title="ODL & OL" />
              <div className="content-part">
                <h4 className="title" title="ODL & OL">
                  ODL & OL
                </h4>
              </div>
            </div>
          </div>
          <div className="col mb-30">
            <div
              className="site-wrap lightBlue-bg text-center"
              onClick={() => externalLink("https://jntukiqac.com")}
            >
              <img src={icon9} alt="IQAC" title="IQAC" />
              <div className="content-part">
                <h4 className="title" title="IQAC">
                  IQAC
                </h4>
              </div>
            </div>
          </div>
          <div className="col mb-30">
            <div
              className="site-wrap lightBlue-bg text-center"
              onClick={() => externalLink("https://jntukdmc.in")}
            >
              <img src={icon2} alt="JMTUK DMC" title="JMTUK DMC" />
              <div className="content-part">
                <h4 className="title" title="JMTUK DMC">
                  JMTUK DMC
                </h4>
              </div>
            </div>
          </div>
          <div className="col mb-30">
            <div
              className="site-wrap lightBlue-bg text-center"
              onClick={() => externalLink("http://jntuknss.org")}
            >
              <img src={icon7} alt="NSS" title="NSS" />
              <div className="content-part">
                <h4 className="title" title="NSS">
                  NSS
                </h4>
              </div>
            </div>
          </div>
          <div className="col mb-30">
            <div
              className="site-wrap lightBlue-bg text-center"
              onClick={() => externalLink("https://ecosak.org.in")}
            >
              <img src={icon4} alt="Alumni" title="Alumni" />
              <div className="content-part">
                <h4 className="title" title="AlumniAlumni">
                  Alumni
                </h4>
              </div>
            </div>
          </div>
          <div className="col mb-30">
            <a
              href={`http://arc.jntuk.edu.in`}
              target="_blank"
              className="site-wrap lightBlue-bg text-center"
              rel="noreferrer"
            >
              <img
                src={icon8}
                alt="Anti Ragging Cell"
                title="Anti Ragging Cell"
              />
              <div className="content-part">
                <h4 className="title" title="Anti Ragging Cell">
                  Anti Ragging Cell
                </h4>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
