import React from 'react';
import { Helmet } from "react-helmet";
import ErrorContent from '../components/Error';
import Header from "../components/Layout/Header/Header";
import Footer from "../components/Layout/Footer/Footer";
import OffWrap from "../components/Layout/Header/OffWrap";
import SearchModal from "../components/Layout/Header/SearchModal";
import Accreditation from "../components/Common/Accreditation";
import footerLogo from "../assets/img/logo/lite-logo.png";

class Error extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Page Not Found | JNTU Kakinada</title>
                    <meta name="description" content="Page Not Found Jawaharlal Nehru Technological University Kakinada" />
                    <meta name="keywords" content="Page Not Found Jawaharlal Nehru Technological University Kakinada"></meta>
                </Helmet>
                <OffWrap />
                <Header
                    parentMenu="home"
                    TopBar="enable"
                    TopBarClass="topbar-area dark-primary-bg hidden-lg"
                />
                <div className="pageTopOffset">
                    <ErrorContent />
                </div>
                <Accreditation />
                <Footer
                    footerClass="rs-footer home9-style main-home"
                    footerLogo={footerLogo}
                />
                <SearchModal />
            </React.Fragment>
        );
    }
}


export default Error;

