import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SearchModal from "../../components/Layout/Header/SearchModal";
import footerLogo from "../../assets/img/logo/lite-logo.png";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import Accreditation from "../../components/Common/Accreditation";
import { ExaminationMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import RightSidebar from "../../components/Common/RightSidebar";
import Pagination from "../../components/Pagination";
import { useFontSize } from '../../FontSizeContext';

const Affiliated_Colleges_East_Godavari = () => {
  const { fontSize } = useFontSize();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const totalItems = data?.meta?.pagination?.total;
  useEffect(() => {
    fetchData(
      JNTUK_API_Urls.Affiliated_Colleges_East_Godavari +
        `&pagination[page]=${currentPage}`,
      setData
    );
  }, [currentPage]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <React.Fragment>
      <Helmet>
        <title>Affiliated Colleges East Godavari | JNTU Kakinada</title>
        <meta name="description" content="Affiliated Colleges East Godavari Jawaharlal Nehru Technological University Kakinada" />
        <meta name="keywords" content="Affiliated Colleges East Godavari Jawaharlal Nehru Technological University Kakinada"></meta>
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="home"
        TopBar="enable"
        TopBarClass="topbar-area dark-primary-bg hidden-lg"
      />
      <style>
        {`
                    body,
                    .rs-latest-events.style1 .event-wrap .events-short .date-part .date,
                    .widget ul li a {
                        font-size: ${fontSize}px;
                    }
                    .rs-inner-blog .blog-item .blog-content .blog-title,
                    .widget h1,.content-wrapper h2, .heading, .college-title{
                        font-size: calc(${fontSize}px + 10px);
                    }
                    .breadcrumb-bg .breadcrumbs-text ul li{
                      font-size: calc(${fontSize}px + 2px);
                    }
                    
                    .full-width-header .rs-header .topbar-area .topbar-right li.login-register a,
                    .readon.orange-btn, .rs-latest-events.bg-wrap .notification-filter button,
                    .rs-latest-events.style1 .event-wrap:not(.ls-area) .events-short .slNo, 
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > a,
                    .full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li.rs-mega-menu .mega-menu .single-megamenu .sub-menu > li > ul li > a,
                    table th, table td {                       
                        font-size: calc(${fontSize}px - 2px);
                    }

                    .rs-footer .footer-top-social .social_msg{
                        font-size: calc(${fontSize}px + 18px);
                    }
                    .rs-footer .footer-center h3{
                        font-size: calc(${fontSize}px + 12px);
                    }
                    .rs-footer.home9-style .footer-top .widget-title,
                    .rs-team.style1 .about-btn--primary,
                    .rs-team.style1 .about-btn--secondary,
                    .course-overview .inner-box h5{
                        font-size: calc(${fontSize}px + 4px);
                    }
                    .rs-footer .footer-center h4,
                    .rightBar h4{
                        font-size: calc(${fontSize}px + 6px);
                    }
                    
                `}
      </style>

      <div className="pageTopOffset">
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          pageTitle="Affiliated Colleges East Godavari"
          pageName="Affiliated Colleges East Godavari"
          breadcrumbsClass="breadcrumb-bg"
        />
        {/* breadcrumb-area-start */}

        <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 md-mb-30">
                <div className="about-intro">
                  <ExaminationMenu />
                  <SideBar />
                </div>
              </div>
              <div className="col-lg-6 pl-20 md-pl-14">
                <div className="row">
                  <div className="rs-inner-blog col-lg-12">
                    <h3 className="college-title" title="Affiliated Colleges East Godavari">
                      Affiliated Colleges East Godavari
                    </h3>
                    <div className="overflow-x">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">CC</th>
                          <th scope="col">COLLEGE NAME</th>
                          <th scope="col">COURSES OFFERED</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data?.map((obj, index) => (
                          <tr>
                            <td> {obj?.attributes?.College_Code}</td>
                            <td>{obj?.attributes?.College_Name}</td>
                            <td>{obj?.attributes?.Courses_Offered}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      paginate={paginate}
                      arrayLength={totalItems / itemsPerPage}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 pl-40 md-pl-14">
                <RightSidebar />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accreditation />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default Affiliated_Colleges_East_Godavari;
