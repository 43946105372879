import React, { useState, useEffect } from "react";
import { AboutMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { BlocksRenderer } from "../../components/BlockRenderer";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import { useParams } from "react-router-dom";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import ScrollToTop from "../../components/Common/ScrollTop";
import RightSidebar from "../../components/Common/RightSidebar";

const getChildPath = (type, setPath) => {
  let text = "SWOC";
  if (!type) {
    setPath({ pageCategory: "", pageName: "SWOC" });
    return null;
  }
  if (type === "institutional_strength") text = "Institutional Strength";
  else if (type === "institutional_weakness") text = "Institutional Weakness";
  else if (type === "institutional_opportunity")
    text = "Institutional Opportunity";
  else if (type === "institutional_challenge") text = "Institutional Challenge";
  setPath({ pageCategory: { label: "SWOC", path: "SWOC" }, pageName: text });
  return null;
};
const ColLayout = () => {
  const [data, setData] = useState([]);
  const [path, setPath] = useState({ pageCategory: "", pageName: "SWOC" });
  const { type } = useParams();
  useEffect(() => {
    getChildPath(type, setPath);
    fetchData(JNTUK_API_Urls.SWOC, setData);
  }, [type]);

  return (
    <React.Fragment>
      <SiteBreadcrumb
        pageCategory={path.pageCategory}
        pageName={path.pageName}
      />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 md-mb-30">
              <div className="about-intro">
                <AboutMenu />
                <SideBar />
              </div>
            </div>

            <div className="col-lg-6 pl-20 md-pl-14">
              <div className="content-wrapper">
                <div className="content-section">
                  <h3
                    style={{ marginBottom: "20px" }}
                    title="Strength, Weakness, Opportunity and Challenges (SWOC)"
                  >
                    Strength, Weakness, Opportunity and Challenges (SWOC)
                  </h3>
                  {(!type || type === "institutional_strength") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes?.Institutional_Strength || []
                      }
                    />
                  )}
                  {(!type || type === "institutional_weakness") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes?.Institutional_Weakness || []
                      }
                    />
                  )}
                  {(!type || type === "institutional_opportunity") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes?.Institutional_Opportunity || []
                      }
                    />
                  )}
                  {(!type || type === "institutional_challenge") && (
                    <BlocksRenderer
                      content={
                        data?.data?.attributes?.Institutional_Challenge || []
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-3 pl-40 md-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default ColLayout;
