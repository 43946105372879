import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import FooterBottom from "./FooterBottom";
import { JNTUK_PDF_Links } from "../../../utils/api.config";
import { imageBaseURL } from "../../../utils/fetchData";

import univBuilding1 from "../../../assets/img/univ-building/1.jpg";
import univBuilding2 from "../../../assets/img/univ-building/2.jpg";
import univBuilding3 from "../../../assets/img/univ-building/3.jpg";
import univBuilding4 from "../../../assets/img/univ-building/4.jpg";
import univBuilding5 from "../../../assets/img/univ-building/5.jpg";
import univBuilding6 from "../../../assets/img/univ-building/6.jpg";
import univBuilding7 from "../../../assets/img/univ-building/7.jpg";
import univBuilding8 from "../../../assets/img/univ-building/8.jpg";

const photos = [
  {
    src: univBuilding1,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding2,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding3,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding4,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding5,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding6,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding7,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
  {
    src: univBuilding8,
    width: 4,
    height: 3,
    alt: "College Building",
    title: "College Building",
  },
];

const Footer = (props) => {
  const { footerLogo, footerClass, footerTopClass } = props;
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const externalLink = (url) => {
    const userConfirmed = window.confirm(
      "You are being redirected to an external website. Please note that JNTUK Portal is not responsible for external websites content & privacy policies."
    );

    if (userConfirmed) {
      window.open(url, "_blank");
    } else {
      return null;
    }
  };
  return (
    <footer className={footerClass ? footerClass : "rs-footer"}>
      <div className="footer-container-ts"></div>
      <div className="container">
        <div className="footer-top-social">
          <div className="row y-middle">
            <div className="social_msg col-md-6">Stay Connected!</div>
            <div className="social_icon_container col-md-6">
              <ul className="social_icon">
                <li className="facebook">
                  <a href="#" aria-label="Facebook">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#" aria-label="Twitter">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="google-plus">
                  <a href="#" aria-label="Google Plus">
                    <i className="fab fa-google-plus"></i>
                  </a>
                </li>
                <li className="pinterest">
                  <a href="#" aria-label="Pinterest">
                    <i className="fab fa-pinterest"></i>
                  </a>
                </li>

                <li className="linkedin">
                  <a href="#" aria-label="Linkedin">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#" aria-label="Instagram">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#" aria-label="Youtube">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-center">
        <div className="container">
          <div className="row y-middle">
            <div className="col-md-4">
              <h3 title="Communication Address">Communication Address</h3>
              <h4 title="The Registrar">The Registrar</h4>
              <p>Jawaharlal Nehru Technological University Kakinada</p>
              <p>Kakinada, Andhra Pradesh 533003</p>
              <p className="mt-20">Phone: +91 884 230 0900</p>
              <p>Fax: +91 884 230 0901</p>
              <div className="rs-gallery mt-20">
                <Gallery
                  columns="4"
                  direction={"column"}
                  photos={photos}
                  onClick={openLightbox}
                />
                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={photos.map((x) => ({
                          ...x,
                          srcset: x.srcSet,
                          caption: x.title,
                        }))}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </div>
            </div>
            <div className="col-md-8">
              <iframe
                title="Location Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d238.49542931082723!2d82.24262115491288!3d16.97814717257171!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a38299cdd8f2049%3A0xb7251fd704659869!2sJawaharlal%20Nehru%20Technological%20University%2C%20Kakinada!5e0!3m2!1sen!2sin!4v1706180831314!5m2!1sen!2sin"
                width="100%"
                height="320"
                allowFullScreen=""
                aria-hidden="false"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className={`footer-top ${footerTopClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h3 className="widget-title" title="About JNTUK">
                About JNTUK
              </h3>
              <ul className="site-map">
                <li>
                  <Link to="/jntukvision">Vision & Mission</Link>
                </li>
                <li>
                  <Link to="/phd-courses">Research and Ranking</Link>
                </li>
                <li>
                  <Link to="/SDD">Strategy Development and Deployment</Link>
                </li>
                <li>
                  <Link to="/institutional-bodies">Institutional Bodies</Link>
                </li>
                <li>
                  <a
                    href={`${imageBaseURL}${JNTUK_PDF_Links.JNTU_Act_Link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    JNTU Act
                  </a>
                </li>
                <li>
                  <a
                    href={`${imageBaseURL}${JNTUK_PDF_Links.RTI_Act_Link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    RTI Act
                  </a>
                </li>
                <li>
                  <a
                    href={`https://docs.google.com/gview?url=${imageBaseURL}${JNTUK_PDF_Links.Anti_Ragging_Link}&embedded=true`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Anti Ragging
                  </a>
                </li>
                <li>
                  {" "}
                  <Link to="/category/Tie-ups-and-Mou’s">Tie Ups & MOU's</Link>
                </li>
              </ul>

              <h3 className="widget-title mt-10" title="Academics">
                Academics
              </h3>
              <ul className="site-map">
                <li>
                  <Link to="/programmes-offered">Courses Offered</Link>
                </li>
                <li>
                  <Link to="/admissions">Admissions</Link>
                </li>
                <li>
                  <Link to="/under-graduate">Affiliated Colleges</Link>
                </li>
                {/* <li>
                  <Link to="#">Academic Regulations</Link>
                </li> */}
                <li>
                  <Link to="/category/Academic-Calendars">
                    Academic Calendars
                  </Link>
                </li>
                <li>
                  <Link to="/category/Academic-Syllabus">Syllabus</Link>
                </li>
              </ul>

              <h3 className="widget-title mt-10" title="OTHER IMPORTANT LINKS">
                OTHER IMPORTANT LINKS
              </h3>
              <div className="inlineLinks">
                <a
                  href={"https://www.ugc.gov.in"}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    externalLink("https://www.ugc.gov.in");
                  }}
                  onContextMenu={(e) => {
                    return null;
                  }}
                >
                  UGC
                </a>
                <a
                  href={"https://www.aicte-india.org"}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    externalLink("https://www.aicte-india.org");
                  }}
                  onContextMenu={(e) => {
                    return null;
                  }}
                >
                  AICTE
                </a>
                <a
                  href={"https://apsche.ap.gov.in"}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    externalLink("https://apsche.ap.gov.in");
                  }}
                  onContextMenu={(e) => {
                    return null;
                  }}
                >
                  APSCHE
                </a>
                <a
                  href={
                    "https://cets.apsche.ap.gov.in/ECET/ECET/ECET_HomePage.aspx"
                  }
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    externalLink(
                      "https://cets.apsche.ap.gov.in/ECET/ECET/ECET_HomePage.aspx"
                    );
                  }}
                  onContextMenu={(e) => {
                    return null;
                  }}
                >
                  AP ECET
                </a>
                <a
                  href={
                    "https://cets.apsche.ap.gov.in/PGECET/PGECET/PGECET_HomePage.aspx"
                  }
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    externalLink(
                      "https://cets.apsche.ap.gov.in/PGECET/PGECET/PGECET_HomePage.aspx"
                    );
                  }}
                  onContextMenu={(e) => {
                    return null;
                  }}
                >
                  AP PGECET
                </a>
                <a
                  href={
                    "https://cets.apsche.ap.gov.in/EAPCET/Eapcet/EAPCET_HomePage.aspx"
                  }
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    externalLink(
                      "https://cets.apsche.ap.gov.in/EAPCET/Eapcet/EAPCET_HomePage.aspx"
                    );
                  }}
                  onContextMenu={(e) => {
                    return null;
                  }}
                >
                  AP EAPCET
                </a>
              </div>

              <h3 className="widget-title mt-10" title="OTHER JNTUK PORTALS">
                OTHER JNTUK PORTALS
              </h3>
              <div className="inlineLinks">
                <Link to="/category/Sports">Sports</Link>
                <Link to="/category/Placement-Notifications">Placements</Link>
                <a
                  href={"https://jntukdaaportal.org"}
                  target="_blank"
                  rel="noreferrer"
                >
                  DAA
                </a>
                <a
                  href={"https://jntukdmc.in"}
                  target="_blank"
                  rel="noreferrer"
                >
                  DMC
                </a>
                <a
                  href={"https://jntukelearn.in/elearn"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Jntuk eLearn
                </a>
                <a
                  href={"https://onlinecourses.nptel.ac.in"}
                  target="_blank"
                  rel="noreferrer"
                >
                  UGC SWAYAM - TVCS & RADIO JINGLES FOR SWAYAM INITIATIVE
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h3 className="widget-title" title="Administration">
                Administration
              </h3>
              <ul className="site-map">
                <li>
                  <Link to="/profile/chancellor">Chancellor</Link>
                </li>
                <li>
                  <Link to="/profile/vice-chancellor">Vice Chancellor</Link>
                </li>
                <li>
                  <Link to="/profile/rector">Rector</Link>
                </li>
                <li>
                  <Link to="/profile/osd">OSD</Link>
                </li>
                <li>
                  <Link to="/profile/registrar">Registrar</Link>
                </li>
                <li>
                  <Link to="/executive-council">Executive Council</Link>
                </li>
                <li>
                  <Link to="/academic-senate">Academic Senate</Link>
                </li>
                <li>
                  <Link to="/board-of-studies">Board of Studies</Link>
                </li>
                <li>
                  <Link to="/governing-hierarchy">Governing Heirarchy</Link>
                </li>
                <li>
                  <Link to="/directorates">Directorates</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h3 className="widget-title" title="Directorates">
                Directorates
              </h3>
              <ul className="site-map">
                <li>
                  <Link to="/profile/academics-and-planning-dap">
                    Director, Academic Planning
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-academic-audit">
                    Director, Academic Audit
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-of-evaluation-de">
                    Director of Evaluation
                  </Link>
                </li>
                <li>
                  <Link to="/profile/admissions-da">Director, Admissions</Link>
                </li>
                <li>
                  <Link to="/profile/director-research-and-development">
                    Director, Research and Development
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-libraryn">
                    Director, ODL & OL Programmes
                  </Link>
                </li>
                <li>
                  <Link to="/profile/faculty-development-centre">
                    Director, Faculty Development Centre
                  </Link>
                </li>
                <li>
                  <Link to="/profile/infrastructure-development">
                    Chief Engineer & Director, Infrastructure
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-foreign-university-relations-dfur">
                    Director, Foreign University Relations
                  </Link>
                </li>
                <li>
                  <Link to="/profile/institute-of-science-and-technology-ist">
                    Director, IST
                  </Link>
                </li>
                <li>
                  <Link to="/profile/internal-quality-assurance-cell">
                    Director, IQAC
                  </Link>
                </li>
                <li>
                  <Link to="/profile/school-of-management-studies">
                    Director - School of Management Studies
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-industry-institute-interaction-placements-and-training">
                    Director, IIIP & Training
                  </Link>
                </li>

                {/* <li>
                  <Link to="/profile/director-green-campus-initiatives">
                    Director, Green Campus Initiatives
                  </Link>
                </li> */}
                <li>
                  <Link to="/profile/director-empowerment-of-women-grievances">
                    Director, Empowerment of Women & Grievances
                  </Link>
                </li>
                {/* <li>
                  <Link to="/profile/director-bics">Director, BICS</Link>
                </li> */}
                <li>
                  <Link to="/profile/director-legal-and-govt-affairs">
                    Director Legal & Government Affairs
                  </Link>
                </li>
                {/* <li>
                  <Link to="/profile/director-university-games-and-sports">
                    Director, University Games & Sports
                  </Link>
                </li>
                <li>
                  <Link to="/profile/director-incubation">
                    Director (i/c) Incubation
                  </Link>
                </li> */}

                {/* <li>
                  <Link
                    onClick={() => externalLink("https://jntukbiotech.com")}
                  >
                    Biotech
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => externalLink("https://jntukfoodtech.com")}
                  >
                    Foodtech
                  </Link>
                </li> */}
                <li>
                  <Link to="/profile/director-it-infrastructure">
                    Director, IT Infrastructure
                  </Link>
                </li>
                <li>
                  <Link to="/profile/research-innovation-centre">
                    Director, Research Innovation Centre
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget">
              <h3
                className="widget-title"
                title="Institute of Science & Technology"
              >
                Institute of Science & Technology
              </h3>
              <ul className="site-map">
                <li>
                  <a
                    href={`${process.env.REACT_APP_JNTUK_BASE_URL}/shsr`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    School of Health Sciences and Research
                  </a>
                </li>
                <li>
                  <a
                    href={"https://jntukfoodtech.com"}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      externalLink("https://jntukfoodtech.com");
                    }}
                    onContextMenu={(e) => {
                      return null;
                    }}
                  >
                    School of Food Technology
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_JNTUK_BASE_URL}/sree`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    School of Renewable Energy & Environment
                  </a>
                </li>
                <li>
                  <a
                    href={"https://jntukbiotech.com"}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      externalLink("https://jntukbiotech.com");
                    }}
                    onContextMenu={(e) => {
                      return null;
                    }}
                  >
                    School of Biotechnology
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_JNTUK_BASE_URL}/soa`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    School of Avionics
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_JNTUK_BASE_URL}/ssit`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    School of Spatial Information Technology
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_JNTUK_BASE_URL}/sopst`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    School of Pharmaceutical Sciences & Technologies
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_JNTUK_BASE_URL}/snt`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    School of Nano Technology
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <FooterBottom />
    </footer>
  );
};

export default Footer;
