import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { JNTUK_PDF_Links } from "../../../utils/api.config";
import { imageBaseURL } from "../../../utils/fetchData";
import useWindowSize from "../../../useWindowSize";

const externalLink = (url) => {
  const userConfirmed = window.confirm(
    "You are being redirected to an external website. Please note that JNTUK Portal is not responsible for external websites content & privacy policies."
  );

  if (userConfirmed) {
    window.open(url, "_blank");
  } else {
  }
};
const subDomain = (url) => {
  const userConfirmed = window.confirm(
    "You are being redirected to an internal sub-domain website. The sub-domain website will open in a New Tab"
  );

  if (userConfirmed) {
    window.open(url, "_blank");
  } else {
  }
};

export const AboutMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();

  return (
    <div className={"widget"}>
      <div className="mb-20">
        <h1 title="About" className={"widget-title"}>
          About{" "}
          <span
            className="fad fa-list-ul d-lg-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        {internalMenuOpen && (
          <>
            <ul>
              <li>
                <strong>
                  <Link to="/jntukvision">
                    Governance, Leadership and Management
                  </Link>
                </strong>
                <ul>
                  <li>
                    <Link to="/jntukvision/institutional_vision_and_leadership">
                      Institutional Vision and Leadership
                    </Link>
                  </li>
                  <li>
                    <Link to="/jntukvision/core_values"> Core Values</Link>
                  </li>
                  <li>
                    <Link to="/jntukvision/institutional_values_and_best_practices">
                      Institutional Values and Best Practices
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <Link to="/SWOC">SWOC </Link>
                </strong>
                <ul>
                  <li>
                    <Link to="/SWOC/institutional_strength">
                      Institutional Strength
                    </Link>
                  </li>
                  <li>
                    <Link to="/SWOC/institutional_weakness">
                      Institutional Weakness
                    </Link>
                  </li>
                  <li>
                    <Link to="/SWOC/institutional_opportunity">
                      Institutional Opportunity
                    </Link>
                  </li>
                  <li>
                    <Link to="/SWOC/institutional_challenge">
                      Institutional Challenge
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/SDD">Strategy Development and Deployment </Link>
              </li>
              <li>
                <Link to="/institutional-bodies">Institutional Bodies</Link>
              </li>

              <li>
                <a
                  href={`${imageBaseURL}${JNTUK_PDF_Links.JNTU_Act_Link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  JNTU Act
                </a>
              </li>
              <li>
                <Link to="/location-map">Location Map</Link>
              </li>
              <li>
                <a
                  href={`${imageBaseURL}${JNTUK_PDF_Links.RTI_Act_Link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RTI Act
                </a>
              </li>
              <li>
                <a
                  href={`https://docs.google.com/gview?url=${imageBaseURL}${JNTUK_PDF_Links.Anti_Ragging_Link}&embedded=true`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Anti Ragging
                </a>
              </li>
              <li>
                <Link to="/university-song">University Song</Link>
              </li>
              <li>
                <Link to="/category/Press-Releases">Media/Press</Link>
              </li>
              <li>
                <Link to="/jntuk-contacts">Contacts</Link>
              </li>
              <li>
                <Link to="/gallery/univ-gallery">Univ Gallery</Link>
              </li>
              <li>
                <Link to="/convocations">Convocations</Link>
              </li>
              <li>
                <Link to="/category/Tie-ups-and-Mou’s">Tie Ups & MOU's</Link>
              </li>
              <li>
                <Link to="/governing-hierarchy">Governing Hierarchy</Link>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export const AdministrationMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className={"widget"}>
      <div className="mb-20">
        <h1 title="Administration" className={"widget-title"}>
          Administration{" "}
          <span
            className="fad fa-list-ul d-lg-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        {internalMenuOpen && (
          <>
            <ul>
              <li>
                <Link to="/profile/chancellor">Chancellor</Link>
              </li>
              <li>
                <Link to="/profile/vice-chancellor">Vice Chancellor</Link>
              </li>
              <li>
                <Link to="/profile/rector">Rector</Link>
              </li>
              <li>
                <Link to="/profile/registrar">Registrar</Link>
              </li>
              <li>
                <Link to="/profile/osd">OSD</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/executive-council">Executive Council</Link>
              </li>
              <li>
                <Link to="/academic-senate">Academic Senate</Link>
              </li>
              <li>
                <Link to="/board-of-studies">Board of Studies</Link>
              </li>
              <li>
                <Link to="/jntuk-vice-chancellor">JNTUK Vice Chancellor's</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/directorates">
                  <strong>Directorates</strong>
                </Link>
                <ul>
                  <li>
                    <Link to="/profile/academics-and-planning-dap">
                      Director, Academic Planning
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/director-academic-audit">
                      Director, Academic Audit
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/director-of-evaluation-de">
                      Director of Evaluation
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/admissions-da">
                      Director, Admissions
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/director-research-and-development">
                      Director, Research and Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/director-libraryn">
                      Director, ODL & OL Programmes
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/faculty-development-centre">
                      Director, Faculty Development Centre
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/infrastructure-development">
                      Chief Engineer & Director, Infrastructure
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/director-foreign-university-relations-dfur">
                      Director, Foreign University Relations
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/institute-of-science-and-technology-ist">
                      Director, IST
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/internal-quality-assurance-cell">
                      Director, IQAC
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/school-of-management-studies">
                      Director - School of Management Studies
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/director-industry-institute-interaction-placements-and-training">
                      Director, IIIP & Training
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/profile/director-green-campus-initiatives">
                      Director, Green Campus Initiatives
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/profile/director-empowerment-of-women-grievances">
                      Director, Empowerment of Women & Grievances
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/profile/director-bics">Director, BICS</Link>
                  </li> */}
                  <li>
                    <Link to="/profile/director-legal-and-govt-affairs">
                      Director (i/c) Legal & Government Affairs
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/profile/director-university-games-and-sports">
                      Director(I/C) Director, University Games & Sports
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/director-incubation">
                      Director(I/C) Incubation
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/profile/director-it-infrastructure">
                      Director (i/c), IT Infrastructure
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile/research-innovation-centre">
                      Director (i/c), Research Innovation Centre
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>

            <ul>
              <li>
                <strong>Principal's</strong>
              </li>
              <li>
                <Link to="/profile/principal-university-college-of-engineering-kakinada">
                  Principal, UCEK
                </Link>
              </li>
              <li>
                <Link to="/profile/principal-university-college-of-engineering-narasaraopeta">
                  Principal, UCEN
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/special-officers">Special Officers</Link>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export const AcademicsMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className={"widget"}>
      <div className="mb-20">
        <h1 title="Academics" className={"widget-title"}>
          Academics
          <span
            className="fad fa-list-ul d-lg-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        {internalMenuOpen && (
          <>
            <ul>
              <li>
                <Link to="/methodology">Methodology</Link>
              </li>
              <li>
                <Link to="/admissions">Admission</Link>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <Link to="/under-graduate">
                    UG - Programmes Offered - Affiliated
                  </Link>
                </strong>
                <ul>
                  <li>
                    <Link to="/course/1">Bachelor of Technology</Link>
                  </li>
                  <li>
                    <Link to="/course/2">Bachelor of Pharmacy</Link>
                  </li>
                  <li>
                    <Link to="/course/3">Bachelor of Architecture</Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <Link to="/post-graduate">
                    PG - Programmers Offered - Affiliated
                  </Link>
                </strong>
                <ul>
                  <li>
                    <Link to="/course/4">Pharm D</Link>
                  </li>
                  <li>
                    <Link to="/course/5">Master of Technology</Link>
                  </li>
                  <li>
                    <Link to="/course/6">Master of Pharmacy</Link>
                  </li>
                  <li>
                    <Link to="/course/7">Master of Computer Applications</Link>
                  </li>
                  <li>
                    <Link to="/course/8">
                      Master of Bussiness Administration
                    </Link>
                  </li>
                  <li>
                    <Link to="/course/9">Management of Applied Management</Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <Link to="/phd-courses">Research</Link>
                </strong>
                <ul>
                  <li>
                    <Link to="/phd-courses/Promotion_of_Research_and_Facilities">
                      Promotion of Research and Facilities
                    </Link>
                  </li>
                  <li>
                    <Link to="/phd-courses/Innovations_and_Extension">
                      Innovations and Extension
                    </Link>
                  </li>
                  <li>
                    <Link to="/phd-courses/Innovation_Ecosystem">
                      Innovation Ecosystem{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/phd-courses/Research_Branches">Branches</Link>
                  </li>
                  <li>
                    <Link to="/phd-courses/Regulations_and_Guidelines">
                      Regulations & Guidelines
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/category/Academic-calendars">
                  Academic Calendars
                </Link>
              </li>
              <li>
                <Link to="/programmes-offered">Programmes Offered</Link>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <Link to="/academicsdata">Circular Aspects</Link>
                </strong>
                <ul>
                  <li>
                    <Link to="/academicsdata/Circular_Aspects">
                      Circular Aspects
                    </Link>
                  </li>
                  <li>
                    <Link to="/academicsdata/Curriculum_Design_and_Development">
                      Curriculum Design and Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/academicsdata/Curriculum_Enrichment">
                      Curriculum Enrichment
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/teachingLearnProcess">
                  Teaching-Learning Process
                </Link>
              </li>
              <li>
                <Link to="/studentsupportprogression">
                  Student Support and Progression
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <Link to="/infrastructurelearning">
                    Infrastructure and Learning Resources
                  </Link>
                </strong>
                <ul>
                  <li>
                    <Link to="/infrastructurelearning/physical_facilities">
                      Physical Facilities
                    </Link>
                  </li>
                  <li>
                    <Link to="/infrastructurelearning/adequate_facilities">
                      Adequate Facilities
                    </Link>
                  </li>
                  <li>
                    <Link to="/infrastructurelearning/learning_resource">
                      Learning Resource
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <Link to="#">Constituent Colleges/Units</Link>
                </strong>
                <ul>
                  <li>
                    <Link
                      onClick={() => externalLink("https://www.jntucek.ac.in")}
                    >
                      University College of Engineering Kakinada Campus
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => externalLink("https://jntukucen.ac.in")}
                    >
                      University College of Engineering Narasaraopet Campus
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() =>
                        subDomain(`${process.env.REACT_APP_JNTUK_BASE_URL}/sms`)
                      }
                    >
                      School of Management Studies
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export const SchoolCampusMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className={"widget"}>
      <div className="mb-20">
        <h1 title="Campus School's" className={"widget-title"}>
          Campus School's
          <span
            className="fad fa-list-ul d-lg-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        {internalMenuOpen && (
          <>
            <ul>
              <li>
                <Link to="#"></Link>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export const ExaminationMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className={"widget"}>
      <div className="mb-20">
        <h1 title="Examination" className={"widget-title"}>
          Examination{" "}
          <span
            className="fad fa-list-ul d-lg-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        {internalMenuOpen && (
          <>
            <ul>
              <li>
                <strong>
                  <Link to="/profile/director-of-evaluation-de">
                    Director of Evaluation
                  </Link>
                </strong>
                <ul>
                  <li>
                    <Link to="/controller-of-examination-ug">
                      Controller of Examinations (UG)
                    </Link>
                  </li>
                  <li>
                    <Link to="/controller-of-examinations-pg-sdc">
                      Controller of Examinations (PG and SDC)
                    </Link>
                  </li>
                  <li>
                    <Link to="/addl-controller-of-examinations">
                      Addl. Controller of Examinations
                    </Link>
                  </li>
                  <li>
                    <Link to="/category/Academic-Calendars">
                      Academic Calendars
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/university-examination-fee-structure-of-various-courses">
                  University Examination Fee Structure
                </Link>
              </li>
              <li>
                <a
                  href={`${imageBaseURL}/uploads/JNTUK_Malpractices_guidelines_0bc4147844.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  JNTUK Malpractice Guidelines
                </a>
              </li>
              <li>
                <a
                  href={`${imageBaseURL}/uploads/1575382865014_Ph_D_Awardees_2016_2019_b3fc9c2ba6.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  PH.D Awardees List 2016-2019
                </a>
              </li>
              <li>
                <a
                  href="https://jntukresults.edu.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Results Portal
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <Link to="#">Affiliated Colleges</Link>
                </strong>
                <ul>
                  <li>
                    <Link to="/affiliated-colleges-east-godavari">
                      Affiliated Colleges – East Godavari
                    </Link>
                  </li>
                  <li>
                    <Link to="/affiliated-colleges-west-godavari">
                      Affiliated Colleges – West Godavari
                    </Link>
                  </li>
                  <li>
                    <Link to="/affiliated-colleges-krishna">
                      Affiliated Colleges – Krishna
                    </Link>
                  </li>
                  <li>
                    <Link to="/affiliated-colleges-guntur">
                      Affiliated Colleges – Guntur
                    </Link>
                  </li>
                  <li>
                    <Link to="/affiliated-colleges-prakasam">
                      Affiliated Colleges – Prakasam
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/autonomous-colleges">
                  List Of Autonomous Colleges
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <Link to="/category/Academic-Syllabus">DOWNLOADS</Link>
                </strong>
                <ul>
                  <li>
                    <Link to="/notifications">Syllabus</Link>
                  </li>
                  <li>
                    <a
                      href={`${imageBaseURL}${JNTUK_PDF_Links["2F_of_UGC"]}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      2F of UGC
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${imageBaseURL}${JNTUK_PDF_Links["12B_of_UGC"]}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      12B of UGC
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${imageBaseURL}${JNTUK_PDF_Links.IT_Policies}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      IT Policies
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export const AccreditationMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className={"widget"}>
      <div className="mb-20">
        <h1 title="Accreditations" className={"widget-title"}>
          Accreditations{" "}
          <span
            className="fad fa-list-ul d-lg-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        {internalMenuOpen && (
          <>
            <ul>
              <li>
                <Link to="#">About NIRF</Link>
                <ul>
                  <li>
                    <Link to="#">Minutes & Meeting</Link>
                  </li>
                  <li>
                    <Link to="#">Course Details</Link>
                  </li>
                  <li>
                    <Link to="#">NAAC Data Files</Link>
                  </li>
                  <li>
                    <Link to="#">Programme Offered</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="#">Feedback Forms</Link>
                <ul>
                  <li>
                    <Link to="#">Alumni</Link>
                  </li>
                  <li>
                    <Link to="#">Faculty</Link>
                  </li>
                  <li>
                    <Link to="#">Industry</Link>
                  </li>
                  <li>
                    <Link to="#">Student</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#">Accreditations</Link>
                <ul>
                  <li>
                    <Link to="#">NAAC Reports</Link>
                  </li>
                  <li>
                    <a
                      href={`${imageBaseURL}${JNTUK_PDF_Links.JNTUK_NAAC_SSR_2023}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JNTUK NAAC SSR 2023
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${imageBaseURL}${JNTUK_PDF_Links.NIRF_Overall_Report_2023}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      NIRF Overall Report -2023
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${imageBaseURL}${JNTUK_PDF_Links.JNTUK_AQAR_2017_18}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      JNTUK AQAR 2017-18
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${imageBaseURL}${JNTUK_PDF_Links.NIRF_Overall_Report_2022}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      NIRF Overall Report -2022
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${imageBaseURL}${JNTUK_PDF_Links.NAAC_Self_Study_Report_Feb_2017}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      NAAC Self Study Report (SSR) - Feb 2017
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${imageBaseURL}${JNTUK_PDF_Links.Annual_Quality_Assurance_Reports}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Annual Quality Assurance Reports(AQAR)
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${imageBaseURL}${JNTUK_PDF_Links.ARAQ_Of_Student_Satisfaction_Survey}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Analysis Report and Questionnaire of Student Satisfaction
                      Survey
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${imageBaseURL}${JNTUK_PDF_Links.National_Institutional_Rankings_Framework_2020}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      National Institutional Rankings Framework (NIRF) – 2020
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
function getUrl(inputString) {
  let stringWithUnderscores = inputString.replace(/ /g, "-");
  stringWithUnderscores = stringWithUnderscores.replace(/&/g, "_");

  return stringWithUnderscores;
}

export const NotificationMenu = ({ list }) => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  let { path } = useRouteMatch();
  return (
    <div className={"widget"}>
      <div className="mb-20">
        <h1 title="Notification Center" className={"widget-title"}>
          Notification Center{" "}
          <span
            className="fad fa-list-ul d-lg-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        {internalMenuOpen && (
          <>
            <ul>
              <li>
                <Link to={`/category/Live-Scrolling`}>Live Scrolling</Link>
              </li>
            </ul>
            <ul>
              <li>
                <strong>Admin Notifications</strong>
                <ul>
                  <li>
                    <Link to="/category/Notifications-and-Circulars">
                      Notifications and Circulars
                    </Link>
                  </li>
                  <li>
                    <Link to="/category/Tenders">Tenders</Link>
                  </li>
                  <li>
                    <Link to="/category/Workshops">Workshops</Link>
                  </li>
                  <li>
                    <Link to="/category/Conferences">Conferences</Link>
                  </li>
                  <li>
                    <Link to="/category/Tie-ups-and-Mou’s">
                      Tie ups and MOU's
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <strong>Academic Notifications</strong>
                <ul>
                  <li>
                    <Link to="/category/Results">JNTUK Results</Link>
                  </li>
                  <li>
                    <Link to="/category/Academic-Calendars">
                      Academic Calendars
                    </Link>
                  </li>
                  <li>
                    <Link to="/category/Academic-Regulations">
                      Academic Regulations
                    </Link>
                  </li>
                  <li>
                    <Link to="/category/Academic-Syllabus">
                      Academic Syllabus
                    </Link>
                  </li>
                  <li>
                    <Link to="/category/Placement-Notifications">
                      Placement Notifications
                    </Link>
                  </li>
                  <li>
                    <Link to="/category/Sports-Notifications">
                      Sports Notifications
                    </Link>
                  </li>
                  <li>
                    <Link to="/category/National-Service-Scheme">
                      National Service Scheme
                    </Link>
                  </li>
                  <li>
                    <Link to="/download-app-formats">
                      Download Application Formats
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <strong>Other News/Events</strong>
                <ul>
                  <li>
                    <Link to="/gallery">Photo Galleries</Link>
                  </li>
                  <li>
                    <Link to="/category/Press-Releases">Press Releases</Link>
                  </li>
                  {/* <li>
                    <a
                      href="#"
                      onClick={() =>
                        externalLink(
                          "https://infyspringboard.onwingspan.com/web/en/page/lex_auth_0136054520526520321"
                        )
                      }
                    >
                      Infosys Springboard
                    </a>
                  </li> */}
                </ul>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export const JNTUKHappeningMenu = () => {
  const { internalMenuOpen, setInternalMenuOpen } = useWindowSize();
  return (
    <div className={"widget"}>
      <div className="mb-20">
        <h1 title="JNTUK Happenings" className={"widget-title"}>
          JNTUK Happenings{" "}
          <span
            className="fad fa-list-ul d-lg-none"
            onClick={() => {
              setInternalMenuOpen(!internalMenuOpen);
            }}
          ></span>
        </h1>
        {internalMenuOpen && (
          <>
            <ul>
              <li>
                <Link to="/gallery/academic-events">Academic Events</Link>
                <ul>
                  <li>
                    <Link to="/convocations">Convocations</Link>
                  </li>
                  <li>
                    <Link to="/gallery/festivals">Festivals</Link>
                  </li>
                  <li>
                    <Link to="/gallery/university-celebrations">
                      University Celebrations
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery/sports-events">Sports Events</Link>
                  </li>
                  <li>
                    <Link to="/category/Press-Releases">Media/Press</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
