/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SideBar from "../../components/Common/SideBar";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  NotificationMenu,
  JNTUKHappeningMenu,
} from "../../components/Common/SidebarMenu";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";
import { BlocksRenderer } from "../../components/Notification Details Renderer";
import GalleryPart from "../../components/Common/GalleryPage";

const Content = () => {
  const { state } = useLocation();
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <SiteBreadcrumb pageName={state?.category || ""} />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 md-mb-30">
              <div className="about-intro">
                {state?.category === "Press Releases" ? (
                  <JNTUKHappeningMenu />
                ) : (
                  <NotificationMenu />
                )}
                <SideBar />
              </div>
            </div>

            <div className="col-lg-6 pl-20 md-pl-14">
              <div className="content-wrapper">
                <div className="content-section">
                  <div className="notficationTitle">
                    {state?.notification?.attributes?.Title}
                  </div>
                  <div className="mb-5 mt-10">
                    <strong>Download(s)</strong>
                  </div>
                  <div className="downloadLinks">
                    <table className="table table-striped table-bordered">
                      <tbody>
                        {state?.notification?.attributes?.Gallery && (
                          <GalleryPart
                            title={state.title || ""}
                            images={
                              state?.notification?.attributes?.Gallery || []
                            }
                          />
                        )}
                        {state?.notification?.attributes?.Pdf_Links && (
                          <BlocksRenderer
                            content={
                              state?.notification?.attributes?.Pdf_Links || []
                            }
                          />
                        )}
                        <BlocksRenderer
                          content={state?.notification?.attributes?.Body || []}
                        />
                      </tbody>
                    </table>
                  </div>
                  <div className="text-center mt-20">
                    <span onClick={goBack} style={{cursor:"pointer"}}>Go Back</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 pl-40 md-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default Content;
