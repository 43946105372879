import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { fetchData, imageBaseURL } from "../../utils/fetchData";
import { JNTUK_API_Urls } from "../../utils/api.config";

const SliderDefault = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData(JNTUK_API_Urls["Home_Page_Banner"], setData);
  }, []);

  const [autoplaySpeed, setAutoplaySpeed] = useState(20000);
  const sliderRef = useRef(null);

  const sliderSettings = {
    dots: false,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: autoplaySpeed,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      console.log(next)
      if (next === 0) {         
          setAutoplaySpeed(20000);
      } if (next === 1) { 
          setAutoplaySpeed(25000);
      } if (next === 2) { 
        setAutoplaySpeed(36000);
      }  if (next === 3) { 
        setAutoplaySpeed(42000);
      } else {
          setAutoplaySpeed(30000);
      }
  } 
  };

  return (
    <React.Fragment>
      <div className="rs-slider main-home">
        <Slider {...sliderSettings} ref={sliderRef}>
          {data?.data?.map((slide) => (
            <div key={slide.id}>
              <div className="slider-content">
                <div className="bgMedia">
                  {slide.attributes.Image.data.attributes.url.includes('.mp4') ? (
                    <video width="100%" height="100%" playsInline autoPlay loop muted preload="auto">
                      <source src={imageBaseURL + slide.attributes.Image.data.attributes.url} type="video/mp4" />
                    </video>
                  ) : (

                    <img src={imageBaseURL + slide.attributes.Image.data.attributes.url} title={slide.attributes.Image.data.attributes.alternativeText} alt={slide.attributes.Image.data.attributes.alternativeText} />
                  )
                  }
                </div>
                <div className="fixedBannerTitle">
                  <div className="container">
                    <div className="content-part">
                      <h2 className="sl-title" title={slide.attributes.Heading}>{slide.attributes.Heading}</h2>
                      <div
                        className="sl-sub-title"
                        dangerouslySetInnerHTML={{
                          __html: slide.attributes.Sub_Heading,
                        }}
                      />
                      {slide?.attributes?.External_Link !== null && (
                        <div className="sl-btn">
                          <a
                            className="readon orange-btn main-home"
                            href={slide?.attributes?.External_Link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {slide?.attributes?.Button_Text}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </React.Fragment>
  );
};

export default SliderDefault;
