import React, { useState, useEffect } from "react";
import { AboutMenu } from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData, imageBaseURL } from "../../utils/fetchData";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";


const SDDContent = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData(JNTUK_API_Urls.University_Song, setData);
  }, []);

  return (
    <React.Fragment>
      <SiteBreadcrumb pageName="University Song" />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 md-mb-30">
              <div className="about-intro">
                <AboutMenu />
                <SideBar />
              </div>
            </div>
            <div className="col-lg-6 pl-20 md-pl-14">
              <div className="content-wrapper">
                <h3 className="heading" title={data?.data?.attributes?.Page_Heading}>
                  {data?.data?.attributes?.Page_Heading}
                </h3>
                <div className="content-section">
                  <a
                    href={`${imageBaseURL}${data?.data?.attributes?.MP3_File?.data?.attributes?.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Play University Song (MP3)
                  </a>
                  <p style={{ marginTop: "30px" }}>
                    Download University Song (lyrics) in PDF
                  </p>
                  <a
                    href={`${imageBaseURL}${data?.data?.attributes?.PDF_File?.data?.attributes?.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 pl-40 md-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default SDDContent;
