import React from "react";
import teamImg2 from "../../../assets/img/about/nehru02.jpg";
import kakinadaport from "../../../assets/img/about/kakinadaport.jpg"

function RightSidebar(props) {
  return (
    <div className="rightBar">
      <div className="rBlock">
        <img src={teamImg2} alt="Pandit Jawaharlal Nehru" title="Pandit Jawaharlal Nehru" className="w-100 mb-10" />
        <h4 title="Pandit Jawaharlal Nehru">Pandit Jawaharlal Nehru</h4>
        <p>
          A University Stands for Humanism, Tolerance, Reason, Progress, The Adventure of Ideas and Search for The Truth.
        </p>
      </div>
      <div className="rBlock">
        <img src={kakinadaport} alt="Kakinada Port" title="Kakinada Port" className="w-100 mb-10" />
        <h4 title="Kakinada Port">Kakinada</h4>
        <p>
          Kakinada (formerly called Cocanada) About this soundpronunciation (help·info) is one of the largest cities and the district headquarters of East Godavari district in the Indian state of Andhra Pradesh.[5] It is the second most populated city of East Godavari district after Rajahmundry. The city has a population of about 4.43 Lakhs as of 2011 census.
        </p>
      </div>
    </div>
  );
}

export default RightSidebar;
