import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  NotificationMenu,
  JNTUKHappeningMenu,
} from "../../components/Common/SidebarMenu";
import SideBar from "../../components/Common/SideBar";
import { JNTUK_API_Urls } from "../../utils/api.config";
import { fetchData } from "../../utils/fetchData";
import { useLocation } from "react-router-dom";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import RightSidebar from "../../components/Common/RightSidebar";
import Pagination from "../../components/Pagination";
import { formatDate } from "../../utils/methods";

function convertUrl(inputString) {
  let stringWithUnderscores = inputString.replace(/-/g, " ");
  stringWithUnderscores = stringWithUnderscores.replace(/_/g, "&");

  return stringWithUnderscores;
}

function convertToPath(inputString) {
  // Replace spaces with dashes and remove special characters
  const path = inputString
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-|-$/g, "");

  // Encode the resulting string
  const encodedPath = encodeURIComponent(path);

  return encodedPath;
}

const Content = () => {
  let location = useLocation();
  const path = location.pathname.split("/")[2];
  const [notificationCategories, setNotificationCategories] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    fetchData(
      JNTUK_API_Urls.Notification_Categories,
      setNotificationCategories
    );
  }, []);
  const getData = (currentPage) => {
    const filter = convertUrl(path);
    if (path === "Live-Scrolling") {
      fetchData(
        JNTUK_API_Urls.Live_Scrolling + `&pagination[page]=${currentPage}`,
        setData
      );
    } else if (path === "Tenders") {
      fetchData(
        JNTUK_API_Urls.Tenders + `&pagination[page]=${currentPage}`,
        setData
      );
    } else if (path === "Press-Releases") {
      fetchData(
        JNTUK_API_Urls.Press_Releases + `&pagination[page]=${currentPage}`,
        setData
      );
    } else if (path === "Latest-Events") {
      fetchData(
        JNTUK_API_Urls.Latest_Events + `&pagination[page]=${currentPage}`,
        setData
      );
    } else {
      fetchData(
        `/notifications?sort=Publish_Date:desc&populate=*&filters[notification_categories][Notification_Category][$contains]=${filter}&pagination[page]=${currentPage}`,
        setData
      );
    }
  };
  useEffect(() => {
    setTotalItems(data?.meta?.pagination?.total / itemsPerPage || 0);
  }, [data?.meta?.pagination?.total]);
  useEffect(() => {
    setCurrentPage(1);
    setTotalItems(0);
    getData(1);
  }, [path]);
  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <SiteBreadcrumb pageName={convertUrl(path) || "Notifications"} />
      <div id="rs-about" className="rs-about style3 pt-50 pb-50 md-pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 md-mb-30">
              <div className="about-intro">
                {path === "Press-Releases" ? (
                  <JNTUKHappeningMenu />
                ) : (
                  <NotificationMenu list={notificationCategories.data} />
                )}

                <SideBar />
              </div>
            </div>

            <div className="col-lg-6 pl-20 md-pl-14">
              <div className="row">
                <div className="col-lg-12">
                  <div className="content-wrapper">
                    <div className="content-section">
                      <h2 title={convertUrl(path) || "Notifications"}>
                        {convertUrl(path) || "Notifications"}
                      </h2>
                      <div className="overflow-x">
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">S.No</th>
                              <th scope="col" style={{ width: "100px" }}>
                                Date
                              </th>
                              <th scope="col">
                                {path === "Tenders"
                                  ? "Tender Description"
                                  : "Notification Description"}
                              </th>
                              {path === "Tenders" && (
                                <th scope="col">Status</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.data?.map((obj, index) => (
                              <tr
                                key={
                                  (currentPage - 1) * itemsPerPage + index + 1
                                }
                              >
                                <td
                                  style={{
                                    color: "#ff5421",
                                    fontWeight: "700",
                                  }}
                                >
                                  {(
                                    (currentPage - 1) * itemsPerPage +
                                    index +
                                    1
                                  )
                                    .toString()
                                    .padStart(2, "0")}
                                  .
                                </td>
                                <td
                                  style={{ width: "100px", fontWeight: "700" }}
                                >
                                  {path === "Live-Scrolling"
                                    ? formatDate(obj?.attributes?.Start_Date)
                                    : formatDate(obj?.attributes?.Publish_Date)}
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: `/notification/${convertToPath(
                                        obj?.attributes?.Title
                                      )}`,
                                      state: {
                                        notification: obj,
                                        category: convertUrl(path),
                                      },
                                    }}
                                  >
                                    {obj?.attributes?.notification_department
                                      ?.data?.attributes?.Prefix
                                      ? obj?.attributes?.notification_department
                                          ?.data?.attributes?.Prefix + " - "
                                      : ""}
                                    {obj?.attributes?.Title}
                                  </Link>
                                </td>
                                {path === "Tenders" && (
                                  <td>
                                    {obj?.attributes?.Status ? (
                                      <span className="status-open">
                                        <b>Open</b>
                                      </span>
                                    ) : (
                                      <span className="status-closed">
                                        <b>Closed</b>
                                      </span>
                                    )}
                                  </td>
                                )}
                              </tr>
                            ))}
                            {data?.data?.length === 0 && (
                              <tr>
                                <td
                                  align="center"
                                  colSpan={path === "Tenders" ? 4 : 3}
                                >
                                  Notifications are not available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                        currentPage={currentPage}
                        paginate={paginate}
                        arrayLength={totalItems}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 pl-40 md-pl-14">
              <RightSidebar />
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop scrollClassName="scrollup orange-color" />
    </React.Fragment>
  );
};

export default Content;
